import React from "react";
import "./style.css"

function InputComponents({ type, state, setState, placeholder, required, error }) {

    return (
        <div>
        <input
            type={type}
            value={state}
            onChange={(e) => setState(e.target.value)}
            placeholder={placeholder}
            required={required}
            className="custom-input " 
            />
            {error && <p className="text-danger h6">{error}</p>}
            </div>
    )
}

export default InputComponents;