import React, { useEffect, useState } from 'react'
import Userinfo from '.';
import axios from 'axios';
import { getStudy, getstudydata } from '../../Utils/Contancts';
import Usertable from './Usertable';
function Info({Userinfo}) {

  
    const [filteredStudyTable, setFilteredStudyTable] = useState([]);


    useEffect(() => {
        const fetchStudies = async () => {
            try {
                // Fetch study data from the API
                const response = await axios.get(getstudydata);
                // Filter study data based on the fullname
                const filteredData = response.data.filter(item => item.username === Userinfo.fullName);
                // Set the filtered study data
                setFilteredStudyTable(filteredData);
            } catch (error) {
                console.error("Error fetching studies:", error);
            }
        };
    
        // Call fetchStudies only when Userinfo.fullName changes
        if (Userinfo.fullName) {
            fetchStudies();
        }
    }, [Userinfo.fullName]);

console.log("filter data",filteredStudyTable);
  return (
   <>
        <div className="row bg-light ps-1">
            {/* <span className="h5 text-dark">User Name : {Userinfo.fullName}</span>
            <span className="h5 text-dark">Email ID : {Userinfo.email}</span>
             <span className="h5 text-dark">Contact No. : {Userinfo.contact}</span>
               */}
            
            <table className="table">
                        
                            <tr>
                                {/* <th scope="col">#</th> */}
                                <th scope="" className='d-table'>User Name: <span className='h6'>{Userinfo.fullName}</span></th>
                                {/* <td className=''>{Userinfo.fullName}</td> */}
                          </tr>
                          <tr>
                          <th scope="col"className='d-table'>Contact: <span className='h6'>{Userinfo.contact}</span></th>
                                {/* <td>{Userinfo.contact}</td> */}
                            
                          </tr>
                          <tr>
                          <th scope="col" className='d-table' >Email: <span className='h6'>{Userinfo.email}</span></th>
                                {/* <td>{Userinfo.contact}</td> */}
                            
                          </tr>
                               
                     
                        
                    </table>
            
            
              </div>
        
                       
   </>
  )
}

export default Info


