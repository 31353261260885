import React, { useState } from "react";
import SignupForm from "../Components/SignupForm";
import LoginForm from "../Components/LoginForm";



function Login() {
    const [flag, setFlag] = useState(false);


    return(
        <div >
            
            <div className="container">
            <div className="input-wrapper">
                <img src="img1.webp" className="img" alt="" />
                {!flag ? <LoginForm /> : <SignupForm />}
            </div >

            {!flag ? (
                <p style={{ cursor: "pointer" }} onClick={() => setFlag(!flag)}>Don't have an account click here to <span style={{color:"red"}}>Signup.</span></p>
            ) : (
                <p style={{ cursor: "pointer" }} onClick={() => setFlag(!flag)}>Already have an Account? Click here to <span style={{color:"red"}}>Login</span></p>
            )}
        </div>
        
        </div>
      
    )
}

export default Login;