import React, { useEffect, useState } from "react";
import { Button } from "antd";
import "./style.css";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import axios from 'axios'
import {getUsernameByEmail} from '../../Utils/Contancts'

function AddStudy() {
  const [username, setUsername] = useState("");   
  const userEmail = localStorage.getItem("email");
  // fetch username by email
  useEffect(() => {
  const fetchUsername = async () => {
      try {
          const response = await axios.post(getUsernameByEmail,
              { userEmail }
          );
          setUsername(response.data.fullname); // Set the retrieved username
      } catch (error) {
          console.error("Error fetching username:", error);
      }
  };
  
  if (userEmail) {
      fetchUsername();
  }
  }, [userEmail]); 



  return (
    <div>
  {username !== "Admin" && (
<Link to='/addnewstudy' className="text-decoration-none"><Button className="add-btn  d-flex justify-content-start ml-10">
        Add New Study
      </Button>
      </Link>
  )}
      {username !== "Admin" && (
      <Link to='/mystudies' className="text-decoration-none">  <Button className="add-btn d-flex justify-content-start ml-10">
        My Studies
      </Button>
      </Link>
   )}

 {username === "Admin" && (
        <Link to='/userinfo' className="text-decoration-none">
          <Button className="add-btn d-flex justify-content-start ml-10">
            All User
          </Button>
        </Link>
      )}

    </div>
  );
}

export default AddStudy;




