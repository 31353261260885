// import { Button } from "antd";
// import React, { useEffect, useState } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import Navbar from "../Header/Navbar";
// import {getUsernameByEmail,getStudy} from'../../Utils/Contancts'
// import './Mystudies.css'

// function Mystudies() {
//     const [search, setSearch] = useState("");
//     const [filteredStudyTable, setFilteredStudyTable] = useState([]);
//     const[studies,setStudies] = useState([])

  
// const [username, setUsername] = useState("");   
// const userEmail = localStorage.getItem("email");
// // fetch username by email
// useEffect(() => {
// const fetchUsername = async () => {
//     try {
//         const response = await axios.post(getUsernameByEmail,
//             { userEmail }
//         );
//         setUsername(response.data.fullname); // Set the retrieved username
//     } catch (error) {
//         console.error("Error fetching username:", error);
//     }
// };

// if (userEmail) {
//     fetchUsername();
// }
// }, [userEmail]);
  


//   useEffect(() => {
//     const fetchStudies = async () => {
//       try {
//         const response = await axios.get(getStudy);
//         setStudies(response.data);
//       } catch (error) {
//         console.error("Error fetching studies:", error);
//       }
//     };
  
//     fetchStudies();
//   }, []);
  
// // console.log("studied",studies);
  

//   useEffect(() => {
//     const filteredData = studies.filter((item) => {
//         const lowerCaseSearch = search.toLowerCase();
//         const lowerCaseStudyname = item.studyname.toLowerCase();
//         const lowerCaseUsername = username.toLowerCase();

//         if (lowerCaseUsername === "admin") {
//             return lowerCaseStudyname.includes(lowerCaseSearch);
//         } else if (username) {
//             return item.username.toLowerCase() === lowerCaseUsername && lowerCaseStudyname.includes(lowerCaseSearch);
//         }
//         return false;
//     });
//     setFilteredStudyTable(filteredData);
// }, [studies, username, search]);


//     return (
// <>

// <Navbar/>

//         <div className="container-fluid">
//             <div className="row">

//                 <div className="col-md-12 bg-light ">
//                     <div className="row f-flex justify-content-end mt-3">
//                         <div className="col-md-4">
//                             <input
//                                 value={search}

//                                 onChange={(e) => setSearch(e.target.value)}
//                                 placeholder="Search By Study Name"
//                                 className="form-control shadow-none border border-secondary rounded"
//                             />
//                         </div>
//                     </div>

// <ul className="d-table">
//     {filteredStudyTable.map((study, index) => (
//     <li className="studylist">
//     <Link to={`/studiesdata/${study._id}`} key={index}>
//                 <Button className="studybtn bg-light">{study.studyname}</Button>
//               </Link>
//         </li>   
//      ))}
      


// </ul>


// {/* {filteredStudyTable.map((study, index) => (
//               <Link to={`/studiesdata/${study._id}`} key={index}>
//                 <Button className="add-btn bg-light">{study.studyname}</Button>
//               </Link>
//             ))} */}

                                   
//                 </div>
//             </div>
//         </div>

// </>
//     )
// }

// export default Mystudies


















import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../Header/Navbar";
import {getUsernameByEmail,getStudy} from'../../Utils/Contancts'
import './Mystudies.css'

function Mystudies() {
    const [search, setSearch] = useState("");
    const [filteredStudyTable, setFilteredStudyTable] = useState([]);
    const[studies,setStudies] = useState([])

  
const userEmail = localStorage.getItem("email");

console.log("my studies email",userEmail);



  useEffect(() => {
    const fetchStudies = async () => {
      try {
        const response = await axios.get(getStudy);
        setStudies(response.data);
      } catch (error) {
        console.error("Error fetching studies:", error);
      }
    };
  
    fetchStudies();
  }, []);
  
console.log("studied",studies);
  

useEffect(() => {
    const filteredData = studies.filter((item) => {
        const lowerCaseSearch = search.toLowerCase();
        const lowerCaseStudyname = item.studyname?.toLowerCase(); // Optional chaining in case `studyname` is undefined
        const lowerCaseUsername = userEmail.toLowerCase();

        if (lowerCaseUsername === "admin@mdfirstresearch.com") {
            return lowerCaseStudyname.includes(lowerCaseSearch); // Admin sees all studies
        } else if (userEmail) {
            // Check if the `email` field exists and matches `userEmail`
            return item.email?.toLowerCase() === lowerCaseUsername && lowerCaseStudyname.includes(lowerCaseSearch);
        }
        return false;
    });
    setFilteredStudyTable(filteredData);
}, [studies, userEmail, search]);



console.log("data filter by email",filteredStudyTable);


    return (
<>

<Navbar/>

        <div className="container-fluid">
            <div className="row">

                <div className="col-md-12 bg-light ">
                    <div className="row f-flex justify-content-end mt-3">
                        <div className="col-md-4">
                            <input
                                value={search}

                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search By Study Name"
                                className="form-control shadow-none border border-secondary rounded"
                            />
                        </div>
                    </div>

<ul className="d-table">
    {filteredStudyTable.map((study, index) => (
    <li className="studylist">
    <Link to={`/studiesdata/${study._id}`} key={index}>
                <Button className="studybtn bg-light">{study.studyname}</Button>
              </Link>
        </li>   
     ))}
      


</ul>



                                   
                </div>
            </div>
        </div>

</>
    )
}

export default Mystudies
