import React, { useState, useEffect, useRef } from "react";
import InputComponents from "../Input";
import Button from "../Button";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./style.css";
import { createlogin } from '../../Utils/Contancts';

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userInput, setUserInput] = useState("");
  const [captchaText, setCaptchaText] = useState("");
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState({});
  const [userType, setUserType] = useState("");
  const [secreateKey, setSecreateKey] = useState("");
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    initializeCaptcha(ctx);
  }, []);

  useEffect(() => {
    if (userInput.length === captchaText.length) {
      handleCaptchaVerification();
    }
  }, [userInput]);

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min));

  const generateCaptchaText = () => {
    let captcha = "";
    for (let i = 0; i < 2; i++) {
      captcha += generateRandomChar(65, 90);
      captcha += generateRandomChar(97, 122);
      captcha += generateRandomChar(48, 57);
    }
    return captcha
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  };

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ["rgb(0,0,0)"];
    const letterSpace = 150 / captcha.length;
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 10;
      ctx.font = "20px Roboto Mono";
      ctx.fillStyle = textColors[Math.floor(Math.random() * 2)];
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,
        Math.floor(Math.random() * 16 + 25),
        100
      );
    }
  };

  const initializeCaptcha = (ctx) => {
    setUserInput("");
    const newCaptcha = generateCaptchaText();
    setCaptchaText(newCaptcha);
    drawCaptchaOnCanvas(ctx, newCaptcha);
  };

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
    if (userInput.length === captchaText.length) {
      handleCaptchaVerification();
    }
  };

  const handleCaptchaVerification = () => {
    const isValid = userInput === captchaText;
    setErrors(prevErrors => ({...prevErrors, captcha: isValid ? '' : 'Incorrect Captcha'}));
    return isValid;
  };

  const validation = () => {
    let isValid = true;
    const err = {};

    if (!email.trim()) {
      err.email = "Invalid email";
      isValid = false;
    }
    if (!password.trim()) {
      err.password = "Invalid password";
      isValid = false;
    }
    if (!agree) {
      err.agree = "You must agree to continue";
      isValid = false;
    }
    if (!handleCaptchaVerification()) {
      err.captcha = "Incorrect Captcha";
      isValid = false;
    }
    setErrors(err);
    return isValid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const valid = validation();
    if (valid) {
      axios.post(createlogin, {
        email,
        password,
      })
      .then(response => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("email", email);
        toast.success("Login Successful");
        navigate("/homepage");
      })
      .catch(error => {
        toast.error("Login failed. Please try again");
      });
    }
  };

  return (
    <div>
      <div className="input">
        <h2>Login</h2>
        <form></form>
        <InputComponents
          state={email}
          setState={setEmail}
          placeholder="Email "
          type="text"
          required={true}
          error={errors.email}
        />
        <InputComponents
          state={password}
          setState={setPassword}
          placeholder="Password"
          type="password"
          required={true}
          error={errors.password}
        />

        <div className="captcha-container">
          <div className="wrapper">
            <canvas
              ref={canvasRef}
              width="150"
              height="50"
              required={true}
            ></canvas>
          </div>
          <input
            type="text"
            id="user-input"
            placeholder="Enter the text"
            value={userInput}
            required={true}
            onChange={handleUserInputChange}
          />

          <span
            id="reload-button"
            onClick={() =>
              initializeCaptcha(canvasRef.current.getContext("2d"))
            }
          >
            <i className="fa-solid fa-rotate-right"></i>
          </span>

          
        </div>





        {errors.captcha && <span className="error " style={{color:"red"}}>{errors.captcha}</span>}

        <div className="d-flex justify-content-center agree ">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexCheckDefault"
            checked={agree}
            onChange={(e) => setAgree(e.target.checked)}
            required={true}
          />
          <label className="form-check-label d-table" htmlFor="flexCheckDefault">
            I Agree
          </label>
        </div>
        {errors.agree && <span className="error " style={{color:"red"}}>{errors.agree}</span>}

        <Button text={"Login"} onClick={handleLogin} />
        <Link to='/forgotpassword' className="text-decoration-none text-dark " style={{marginLeft: "50px"}}>Forgot Password</Link>

      </div>
    </div>
  );
}

export default LoginForm;
