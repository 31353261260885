


export const getstudybyid = "https://www.researchschedule.com/getstudybyid/"
export const updatestudybyid = "https://www.researchschedule.com/updatestudybyid/"



// admin login api 
export const createAdminLogin  = "https://www.researchschedule.com/createAdminLogin"
export const getAdminnameByEmail = "https://www.researchschedule.com/getAdminnameByEmail"



// login api
export const createlogin = "https://www.researchschedule.com/createlogin"

// sign up api
export const createSignup = "https://www.researchschedule.com/createSignup"

// Navbar api
export const getUsernameByEmail = "https://www.researchschedule.com/getUsernameByEmail"
export const createlogout = "https://www.researchschedule.com/createlogout"


// add study form api 
export const createAddstudy = "https://www.researchschedule.com/createAddstudy"



// edit form api

export const getstudydatabyid = "https://www.researchschedule.com/getstudydatabyid/"
export const update = "https://www.researchschedule.com/update/"



// forgot password api
export const forgotpass = "https://www.researchschedule.com/forgotpass/id"

// show studies api particular username
export const getStudy = "https://www.researchschedule.com/getstudy"





// studies data form  add study with multiple subject no 
export const study="https://www.researchschedule.com/study"

export const getstudydata= "https://www.researchschedule.com/getstudydata"
export const deletestudy = "https://www.researchschedule.com/deletestudy/"


// all user api
export const alluser ="https://www.researchschedule.com/alluser"