// // // first options

// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import { Link, useParams } from 'react-router-dom';
// import { getStudy, getUsernameByEmail, getstudybyid, getstudydata } from '../../Utils/Contancts';
// import { Button } from "antd";
// import Navbar from '../Header/Navbar';
// import './Tabledata.css'

// import {useReactToPrint} from 'react-to-print'
// import { IoClose } from "react-icons/io5";
// import Tabledataform from './Tabledataform';
// import Tabledatainfo from './Tabledatainfo';


// function TableData() {
//     const [rows, setRows] = useState([0]);
   
//     const [show,setShow] = useState(false)
   
//    const [datastudy,setDatastudy] = useState([])
//    const [datasubjectno,setDatasubjectno] = useState([])
   
//    const [finaldata,setFinaldata] = useState([])
// const [studyname,setStudyname] = useState([])

// const [selectedValue, setSelectedValue] = useState('');
// const [datasubject, setDatasubject] = useState([]);
   
// const [search, setSearch] = useState("");


// const [filteredStudyTable, setFilteredStudyTable] = useState([]);



// const [username, setUsername] = useState("");   
// const userEmail = localStorage.getItem("email");
// // fetch username by email
// useEffect(() => {
// const fetchUsername = async () => {
//     try { 
//         const response = await axios.post(getUsernameByEmail,
//             { userEmail }
//         );
//         setUsername(response.data.fullname); // Set the retrieved username
//     } catch (error) {
//         console.error("Error fetching username:", error);
//     }
// };

// if (userEmail) {
//     fetchUsername();
// }
// }, [userEmail]);
  




// useEffect(() => {
//     const fetchStudies = async () => {
//         try {
//             let response;
//             if (username === "Admin") {
//                 response = await axios.get(getstudydata);
//             } else {
//                 response = await axios.get(getstudydata);
//                 const filteredData = response.data.filter(item => item.username === username);
//                 response.data = filteredData;
//             }
//             setDatasubjectno(response.data);
//         } catch (error) {
//             console.error("Error fetching studies:", error);
//         }
//     };

//     if (username) {
//         fetchStudies();
//     }
// }, [username]);



// useEffect(() => {
//     const fetchStudies = async () => {
//         try {
//             let response;
//             if (username === "Admin") {
//                 response = await axios.get(getStudy);
//             } else {
//                 response = await axios.get(getStudy);
//                 const filteredData = response.data.filter(item => item.username === username);
//                 response.data = filteredData;
//             }
//             setDatastudy(response.data);
//         } catch (error) {
//             console.error("Error fetching studies:", error);
//         }
//     };

//     if (username) {
//         fetchStudies();
//     }
// }, [username]);

// useEffect(() => {
//     const fetchStudies = async () => {
//         try {
//             let response;
//             if (username === "Admin") {
//                 response = await axios.get(getstudydata);
//             } else {
//                 response = await axios.get(getstudydata);
//                 // const filteredData = response.data.filter(item => item.username === username);
//                 const filteredData = response.data.filter(item => item.username === username && item.subjectNo === selectedValue);

//                 response.data = filteredData;
//             }
//             setDatasubject(response.data);
//         } catch (error) {
//             console.error("Error fetching studies:", error);
//         }
//     };

//     if (username) {
//         fetchStudies();
//     }
// }, [username,selectedValue]);


// useEffect(() => {
//     const filteredData = datastudy.filter((item) => {
//         const lowerCaseSearch = search.toLowerCase();
//         const lowerCaseStudyname = item.studyname.toLowerCase();
//         const lowerCaseUsername = username.toLowerCase();

//         if (lowerCaseUsername === "admin") {
//             return lowerCaseStudyname.includes(lowerCaseSearch);
//         } else if (username) {
//             return item.username.toLowerCase() === lowerCaseUsername && lowerCaseStudyname.includes(lowerCaseSearch);
//         }
//         return false;
//     });
//     setFilteredStudyTable(filteredData);
// }, [datastudy, username, search]);




// // console.log("admin data",filteredStudyTable);

//     return (
//         <>
                  
// <Navbar/>
// <div className="mt-10">
//     <div className="container-fluid">
//         <div className="row d-flex justify-content-center">
//             <div className="col-md-12 bg-light">
//                 {/* <h3 className='d-table'>Study Information</h3> */}
          
//                 <div className="container-fluid">
//             <div className="row">


//             {username !== "Admin" && (
//     <>
//                 <div className="col-md-3">

//                 <input
//                                 placeholder="Search By Study Name"
//                                 className="form-control shadow-none border border-secondary rounded"
//                                 value={search}

//                                 onChange={(e) => setSearch(e.target.value)}
                              
//                         />
// {filteredStudyTable.map(study => {
//     const filteredSubjects = datasubjectno.filter(subject => subject.studyname === study.studyname);
    
//     return (
//         <div key={study.id}> 
//             <p className='d-table fs-5 fw-bold'>{study.studyname}</p>
//             {filteredSubjects.length > 0 ? (
//                 <select className='d-table' onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
//                     <option>Subject No.</option>
//                     {filteredSubjects.map(filteredSubject => (
//                         <option key={filteredSubject.subjectNo} value={filteredSubject.subjectNo}>{filteredSubject.subjectNo}</option>
//                     ))}
//                 </select>
//             ) : (
//               null  // <p>No subject numbers available for this study.</p>
//             )}
//         </div>
//     );

// })}
//                 </div>
//                 </>
// )}

// <Tabledatainfo subjectinfo={datasubject}/>

//             </div>
//         </div>



//             </div>
//         </div>
//     </div>
// </div>

      


        
//         </>
//     );
// }

// export default TableData;
















// // first options

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { getStudy, getUsernameByEmail, getstudybyid, getstudydata } from '../../Utils/Contancts';
import { Button } from "antd";
import Navbar from '../Header/Navbar';
import './Tabledata.css'

import {useReactToPrint} from 'react-to-print'
import { IoClose } from "react-icons/io5";
import Tabledataform from './Tabledataform';
import Tabledatainfo from './Tabledatainfo';


function TableData() {
    const [rows, setRows] = useState([0]);
   
    const [show,setShow] = useState(false)
   
   const [datastudy,setDatastudy] = useState([])
   const [datasubjectno,setDatasubjectno] = useState([])
   
   const [finaldata,setFinaldata] = useState([])
const [studyname,setStudyname] = useState([])

const [selectedValue, setSelectedValue] = useState('');
const [datasubject, setDatasubject] = useState([]);
   
const [search, setSearch] = useState("");


const [filteredStudyTable, setFilteredStudyTable] = useState([]);



const userEmail = localStorage.getItem("email");





useEffect(() => {
    const fetchStudies = async () => {
        try {
            let response;
            if (userEmail === "admin@mdfirstresearch.com") {
                response = await axios.get(getstudydata);
            } else {
                response = await axios.get(getstudydata);
                const filteredData = response.data.filter(item => item.email === userEmail);
                response.data = filteredData;
            }
            setDatasubjectno(response.data);
        } catch (error) {
            console.error("Error fetching studies:", error);
        }
    };

    if (userEmail) {
        fetchStudies();
    }
}, [userEmail]);



useEffect(() => {
    const fetchStudies = async () => {
        try {
            let response;
            if (userEmail === "admin@mdfirstresearch.com") {
                response = await axios.get(getStudy);
            } else {
                response = await axios.get(getStudy);
                const filteredData = response.data.filter(item => item.email === userEmail);
                response.data = filteredData;
            }
            setDatastudy(response.data);
        } catch (error) {
            console.error("Error fetching studies:", error);
        }
    };

    if (userEmail) {
        fetchStudies();
    }
}, [userEmail]);

useEffect(() => {
    const fetchStudies = async () => {
        try {
            let response;
            if (userEmail === "admin@mdfirstresearch.com") {
                response = await axios.get(getstudydata);
            } else {
                response = await axios.get(getstudydata);
                // const filteredData = response.data.filter(item => item.username === username);
                const filteredData = response.data.filter(item => item.email === userEmail && item.subjectNo === selectedValue);

                response.data = filteredData;
            }
            setDatasubject(response.data);
        } catch (error) {
            console.error("Error fetching studies:", error);
        }
    };

    if (userEmail) {
        fetchStudies();
    }
}, [userEmail,selectedValue]);


useEffect(() => {
    const filteredData = datastudy.filter((item) => {
        const lowerCaseSearch = search.toLowerCase();
        const lowerCaseStudyname = item.studyname.toLowerCase();
        const lowerCaseUsername = userEmail.toLowerCase();

        if (lowerCaseUsername === "admin@mdfirstresearch.com") {
            return lowerCaseStudyname.includes(lowerCaseSearch);
        } else if (userEmail) {
            return item.email.toLowerCase() === lowerCaseUsername && lowerCaseStudyname.includes(lowerCaseSearch);
        }
        return false;
    });
    setFilteredStudyTable(filteredData);
}, [datastudy, userEmail, search]);




// console.log("admin data",filteredStudyTable);

    return (
        <>
                  
<Navbar/>
<div className="mt-10">
    <div className="container-fluid">
        <div className="row d-flex justify-content-center">
            <div className="col-md-12 bg-light">
                {/* <h3 className='d-table'>Study Information</h3> */}
          
                <div className="container-fluid">
            <div className="row">


            {userEmail !== "admin@mdfirstresearch.com" && (
    <>
                <div className="col-md-3">

                <input
                                placeholder="Search By Study Name"
                                className="form-control shadow-none border border-secondary rounded"
                                value={search}

                                onChange={(e) => setSearch(e.target.value)}
                              
                        />
{filteredStudyTable.map(study => {
    const filteredSubjects = datasubjectno.filter(subject => subject.studyname === study.studyname);
    
    return (
        <div key={study.id}> 
            <p className='d-table fs-5 fw-bold'>{study.studyname}</p>
            {filteredSubjects.length > 0 ? (
                <select className='d-table' onChange={(e) => setSelectedValue(e.target.value)} value={selectedValue}>
                    <option>Subject No.</option>
                    {filteredSubjects.map(filteredSubject => (
                        <option key={filteredSubject.subjectNo} value={filteredSubject.subjectNo}>{filteredSubject.subjectNo}</option>
                    ))}
                </select>
            ) : (
              null  // <p>No subject numbers available for this study.</p>
            )}
        </div>
    );

})}
                </div>
                </>
)}

<Tabledatainfo subjectinfo={datasubject}/>

            </div>
        </div>



            </div>
        </div>
    </div>
</div>

      


        
        </>
    );
}

export default TableData;
















