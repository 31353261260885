

// import React, { useState } from "react";
// import InputComponents from "../Input";
// import Button from "../Button";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { createSignup } from '../../Utils/Contancts';
// import LoginForm from "../LoginForm";

// function SignupForm() {
//   const [fullName, setFullName] = useState("");
//   const [contact, setContact] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPass, setConfirmPass] = useState("");
//   const [errors, setErrors] = useState({});
//   const [flag, setFlag] = useState(false);
//   const navigate = useNavigate();

//   const formValidation = () => {
//     let valid = true;
//     const newErr = {};

//     if (!fullName.trim()) {
//       newErr.fullName = "Name is required";
//       valid = false;
//     }
//     if (!contact.trim()) {
//       newErr.contact = "Contact number is required";
//       valid = false;
//     }
//     if (!email.trim()) {
//       newErr.email = "Email is required";
//       valid = false;
//     }
//     if (!password.trim()) {
//       newErr.password = "Password is required";
//       valid = false;
//     } else if (password.length < 6) {
//       newErr.password = "Password must be at least 6 characters long";
//       valid = false;
//     }
//     if (!confirmPass.trim()) {
//       newErr.confirmPass = "Confirm Password is required";
//       valid = false;
//     } else if (confirmPass !== password) {
//       newErr.confirmPass = "Passwords do not match";
//       valid = false;
//     }
//     setErrors(newErr);
//     return valid;
//   };

//   const handleSignup = (e) => {
//     e.preventDefault();
//     const isValid = formValidation();
    
//     if (isValid) {
//       axios
//         .post(createSignup, {
//           fullName,
//           contact,
//           email,
//           password,
//         })
//         .then((response) => {
//           console.log("Signup response..", response);
//           toast.success("SignUp Successfully! Please Login");
//           setFlag(true);
//           navigate("/");
//         })
//         .catch((error) => {
//           if (error.response && error.response.data && error.response.data.error) {
//             toast.error(error.response.data.error);
//           } else {
//             toast.error("An error occurred. Please try again later.");
//           }
//         });
//     }
//   };

//   return (
//     <div>
//       {flag ? (
//         <LoginForm />
//       ) : (
//         <div className="input">
//           <h2>SignUp</h2>
//           <form>
//             <InputComponents
//               state={fullName}
//               setState={setFullName}
//               placeholder="Full Name fdfd"
//               type="text"
//               error={errors.fullName}
//             />
//             <InputComponents
//               state={contact}
//               setState={setContact}
//               placeholder="Contact Number"
//               type="number"
//               required={true}
//               error={errors.contact}
//             />
//             <InputComponents
//               state={email}
//               setState={setEmail}
//               placeholder="Email"
//               type="text"
//               error={errors.email}
//             />
//             <InputComponents
//               state={password}
//               setState={setPassword}
//               placeholder="Password"
//               type="password"
//               error={errors.password}
//             />
//             <InputComponents
//               state={confirmPass}
//               setState={setConfirmPass}
//               placeholder="Confirm Password"
//               type="password"
//               error={errors.confirmPass}
//             />
//             <Button text={"Signup"} onClick={handleSignup} />
//           </form>
//         </div>
//       )}
//     </div>
//   );
// }

// export default SignupForm;




import React, { useState } from "react";
import InputComponents from "../Input";
import Button from "../Button";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createSignup } from '../../Utils/Contancts';
import LoginForm from "../LoginForm";

function SignupForm() {
  const [fullName, setFullName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [errors, setErrors] = useState({});
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();

  const formValidation = () => {
    let valid = true;
    const newErr = {};

    if (!fullName.trim()) {
      newErr.fullName = "Name is required";
      valid = false;
    }
    if (!contact.trim()) {
      newErr.contact = "Contact number is required";
      valid = false;
    }
    if (!email.trim()) {
      newErr.email = "Email is required";
      valid = false;
    }
    if (!password.trim()) {
      newErr.password = "Password is required";
      valid = false;
    } else if (password.length < 6) {
      newErr.password = "Password must be at least 6 characters long";
      valid = false;
    }
    if (!confirmPass.trim()) {
      newErr.confirmPass = "Confirm Password is required";
      valid = false;
    } else if (confirmPass !== password) {
      newErr.confirmPass = "Passwords do not match";
      valid = false;
    }
    setErrors(newErr);
    return valid;
  };

  const handleSignup = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    
    if (isValid) {
      axios
        .post(createSignup, {
          fullName,
          contact,
          email,
          password,
        })
        .then((response) => {
          console.log("Signup response..", response);
          toast.success("SignUp Successfully! Please Login");
          setFlag(true);
          navigate("/");
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.error) {
            toast.error(error.response.data.error);
          } else {
            toast.error("An error occurred. Please try again later.");
          }
        });
    }
  };

  return (
    <div>
      {flag ? (
        <LoginForm />
      ) : (
        <div className="input">
          <h2>SignUp</h2>
          <form>
            <InputComponents
              state={fullName}
              setState={setFullName}
              placeholder="Full Name"
              type="text"
              error={errors.fullName}
            />
            <InputComponents
              state={contact}
              setState={setContact}
              placeholder="Contact Number"
              type="number"
              required={true}
              error={errors.contact}
            />
            <InputComponents
              state={email}
              setState={setEmail}
              placeholder="Email"
              type="text"
              error={errors.email}
            />
            <InputComponents
              state={password}
              setState={setPassword}
              placeholder="Password"
              type="password"
              error={errors.password}
            />
            <InputComponents
              state={confirmPass}
              setState={setConfirmPass}
              placeholder="Confirm Password"
              type="password"
              error={errors.confirmPass}
            />
            <Button text={"Signup"} onClick={handleSignup} />
          </form>
        </div>
      )}
    </div>
  );
}

export default SignupForm;
