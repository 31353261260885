import React, { useState } from "react";
import InputComponents from "../Input";
import Button from "../Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { createlogin, forgotpass } from '../../Utils/Contancts'
import { toast } from "react-toastify";


function ForgotPass() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleforgot = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(forgotpass, {
        email,
      });
      console.log("response..", response);
      toast.success("Password reset link sent to your email!");
      // navigate("/");

    }
    catch (error) {
      console.log("error...", error);
      toast.error("Failed to send password reset link. Please try again.");
    }
  }
  // console.log("...", email);

  return (
    <div>
      <div className="d-flex flex-column justify-content-center mt-5 ">
        <div className="d-flex  justify-content-center">
          <form className="d-flex flex-column  bg-light p-2 rounded" style={{ justifyContent: "center", width: "450px" }}>
            <h3>Forgot Password</h3>
            <InputComponents
              state={email}
              setState={setEmail}
              placeholder="Email"
              type="text"
            />
            <Button text={"Send"} onClick={handleforgot} />

            <div className="text-center mt-3">
              <p>Remembered your password? <Link to="/" className="text-decoration-none" style={{ color: "red" }}>Log in here</Link>.</p>
            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default ForgotPass