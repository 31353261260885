





// import React, { useEffect, useState } from "react";
// import { Button } from "antd";
// import axios from "axios";
// import { toast } from 'react-toastify';
// import Navbar from '../Header/Navbar.jsx'
// import { createAddstudy, getStudy, getUsernameByEmail } from '../../Utils/Contancts.js'
// import './Addstudy.css'
// import { useNavigate } from "react-router-dom";
// import { IoClose } from "react-icons/io5";

// function Addnewstudy() {
//     const [rows, setRows] = useState([0]);
//     const [studyName, setStudyName] = useState("");
//     const navigate = useNavigate()


//     const [rowData, setRowData] = useState([{ visit: "", isCustom: false }]);


// const [study,setStudy] = useState([])
  
// const [studyNameError, setStudyNameError] = useState("");
// const [visitErrors, setVisitErrors] = useState([]);


//     const [username, setUsername] = useState("");   
//     const userEmail = localStorage.getItem("email");
//     const[studies,setStudies] = useState([])

//     useEffect(() => {
//         const fetchUsername = async () => {
//             try {
//                 const response = await axios.post(getUsernameByEmail,
//                     { userEmail }
//                 );
//                 setUsername(response.data.fullname); // Set the retrieved username
//             } catch (error) {
//                 console.error("Error fetching username:", error);
//             }
//         };
        
//         if (userEmail) {
//             fetchUsername();
//         }
//         }, [userEmail]);
    


//         useEffect(() => {
//             const fetchStudies = async () => {
//               try {
//                 const response = await axios.get(getStudy);
//                 setStudies(response.data);
//               } catch (error) {
//                 console.error("Error fetching studies:", error);
//               }
//             };
          
//             fetchStudies();
//           }, []);



//     const handleAddRow = () => {
//         const newRowData = [...rowData];
//         newRowData.push({ visit: "", isCustom: false });
//         setRowData(newRowData);
//         const newRows = [...rows];
//         newRows.push(rows.length);
//         setRows(newRows);
//     };


    
//     const handleRemoveRow = (index) => {
//         const newRowData = [...rowData];
//         newRowData.splice(index, 1);
//         setRowData(newRowData);
//         const newRows = [...rows];
//         newRows.splice(index, 1);
//         setRows(newRows);
//     };

//     const handleVisitChange = (index, value) => {
//         const newRowData = [...rowData];
//         newRowData[index].visit = value;
//         newRowData[index].isCustom = value === "custom";
//         setRowData(newRowData);
//     };

// const handleCustomVisitChange = (index, value) => {
//     const updatedRowData = [...rowData];
//     updatedRowData[index].customVisit = value;
//     setRowData(updatedRowData);
// };
// const validateInputs = () => {
//     let isValid = true;
//     if (studyName.trim() === "") {
//         setStudyNameError("Please enter a study name");
//         isValid = false;
//     } else {
//         setStudyNameError("");
//     }

//     const errors = [];
//     for (let i = 0; i < rows.length; i++) {
//         const visit = document.getElementsByName(`visit_${i}`)[0].value;
//         const day = document.getElementsByName(`day_${i}`)[0].value;
//         const dayunit = document.getElementsByName(`dayunit_${i}`)[0].value;

//         if (visit === "---Select Visit---") {
//             errors.push(`Please Select Visit`);
//             isValid = false;
//         }

//         if (day === "" || isNaN(day)) {
//             errors.push(`* Number`);
//             isValid = false;
//         }

//         if (dayunit === "---Select Day---") {
//             errors.push(`* Day`);
//             isValid = false;
//         }
//     }
//     setVisitErrors(errors);
//     return isValid;
// };


// const handleSave = async (e) =>{
//     e.preventDefault();
  
    
//     const studyNames = studies.map(item => ({ studyname: item.studyname, username: item.username }));
  
//     // Validate inputs
//     if (!validateInputs()) {
//       return;
//     }

//       const details = [];

//   for (let i = 0; i < rows.length; i++) {
//     // const visit = document.getElementsByName(`visit_${i}`)[0].value;
  
//     let visit = document.getElementsByName(`visit_${i}`)[0].value;
//     // let customVisit = '';
//     if (visit === 'custom') {
//         visit = document.getElementsByName(`customVisit_${i}`)[0].value;
//     }
  
  
//     const day = document.getElementsByName(`day_${i}`)[0].value;
//     const dayunit = document.getElementsByName(`dayunit_${i}`)[0].value;
//     const minus = document.getElementsByName(`minus_${i}`)[0].value;
//     const minusunit = document.getElementsByName(`minusunit_${i}`)[0].value;
//     const plus = document.getElementsByName(`plus_${i}`)[0].value;
//     const plusunit = document.getElementsByName(`plusunit_${i}`)[0].value;

//     details.push({visit, day, dayunit, minus, minusunit, plus, plusunit });
//   }
    

// const isStudyNameExists = studyNames.some(item => item.studyname === studyName);
//     if (isStudyNameExists) {
//     toast.success("Study Name Already Exist");
//     return;
//     }
  
//     try {
//       const studyResponse = await axios.post(createAddstudy, {
//         username: username, 
//         studyname: studyName,
//         details: details
//       });
  
//       // Display success message
//       toast.success("Study Added Successfully");
//       navigate('/mystudies');
//       setStudyName(''); 
  
//       // Reset input fields
//       for (let i = 0; i < rows.length; i++) {
//         document.getElementsByName(`visit_${i}`)[0].value = '';
//         document.getElementsByName(`day_${i}`)[0].value = '';
//         document.getElementsByName(`dayunit_${i}`)[0].value = '';
//         document.getElementsByName(`minus_${i}`)[0].value = '';
//         document.getElementsByName(`minusunit_${i}`)[0].value = '';
//         document.getElementsByName(`plus_${i}`)[0].value = '';
//         document.getElementsByName(`plusunit_${i}`)[0].value = '';
//       }
  
//       // Log success
//     //   console.log("Study added successfully:", studyResponse.data);
//     } catch (error) {
//       // Handle error
//       console.error("Error adding study:", error);
//     }

  
// }

// console.log("study name",studies);
//       return (
//         <>
//             <Navbar />
//             <div className="mt-10">
//                 <div className="container-fluid">
//                     <div className="row d-flex justify-content-center">
//                         <div className="col-md-10 bg-light">
//                             <h2>Add Study Form</h2>
//                             <div className="row">
//                                 <div className="studyname col-md-2">
//                                     <span className="fs-5 text-dark">Study Name</span>
//                                 </div>
//                                 <div className="col-md-8">
//                                     <input
//                                         name="studyname"
//                                         type="text"
//                                         className="form-control"
//                                         placeholder="Enter Study Name"
//                                         value={studyName}
//                                         onChange={(e) => setStudyName(e.target.value)}
//                                     />
//                                       {studyNameError && <p className="text-danger d-table">{studyNameError}</p>}
                           
//                                 </div>
//                             </div>
                    



// {rows.map((row, index) => (
//     <div className="addmore row mt-3" key={index}>
//         <div className="col-md-2">
//             <span className="fs-6 text-dark d-table">Visit</span>
//             <select
//                 className="form-control"
//                 name={`visit_${index}`}
//                 value={rowData[index].visit}
//                 onChange={(e) => handleVisitChange(index, e.target.value)}
//             >
//                 <option value="">---Select Visit---</option>
//                 <option value="Visit 1">Visit 1</option>
//                 <option value="Visit 2">Visit 2</option>
//                 <option value="Visit 3">Visit 3</option>
//                 <option value="Visit 4">Visit 4</option>
//                 <option value="custom">Custom</option>
//             </select>
//         </div>
      
//         <div className="col-md-1">
//             <span className="fs-6 text-dark d-table">Day</span>
//             <input
//                 name={`day_${index}`}
//                 type="number"
//                 className="form-control w-75 no-scrollbar"
//             />
//         </div>
//         <div className="col-md-2 mt-4">
//             <select className="form-control" name={`dayunit_${index}`}>
//                 <option>---Select Day---</option>
//                 <option value="Day">Days</option>
//                 <option value="Week">Weeks</option>
//                 <option value="Month">Months</option>
//                 <option value="Year">Years</option>
//             </select>
//         </div>
//         <div className="col-md-1">
//             <span className="fs-6 text-dark d-table">Minus</span>
//             <input
//                 name={`minus_${index}`}
//                 type="number"
//                 className="form-control w-75 no-scrollbar"
//             />
//         </div>
//         <div className="col-md-2 mt-4">
//             <select className="form-control" name={`minusunit_${index}`}>
//                 <option>---Select---</option>
//                 <option value="Day">Days</option>
//                 <option value="Week">Weeks</option>
//                 <option value="Month">Months</option>
//                 <option value="Year">Years</option>
//             </select>
//         </div>
//         <div className="col-md-1">
//             <span className="fs-6 text-dark d-table">Plus</span>
//             <input
//                 name={`plus_${index}`}
//                 type="number"
//                 className="form-control w-75 no-scrollbar"
//             />
//         </div>
//         <div className="col-md-2 mt-4">
//             <select className="form-control" name={`plusunit_${index}`}>
//                 <option>---Select---</option>
//                 <option value="Day">Days</option>
//                 <option value="Week">Weeks</option>
//                 <option value="Month">Months</option>
//                 <option value="Year">Years</option>
//             </select>
//         </div>
//         {index !== 0 && (
//             <div className="col-md-1 mt-4">
//                 <button
//                     className="remove-btn border-0"
//                     onClick={() => handleRemoveRow(index)}
//                 >
//                     <IoClose className="w-100 text-danger" />
//                 </button>
//             </div>
//         )}

// {rowData[index].visit === 'custom' && (
//             <div className="col-md-2">
//                 <input
//                    name={`customVisit_${index}`}
                  
//                     type="text"
//                     className="form-control"
//                     placeholder="Enter Custom Visit"
//                     onChange={(e) => handleCustomVisitChange(index, e.target.value)}
//                 />
//             </div>
//         )}
//     </div>
// ))}
//  {visitErrors.map((error, index) => (
//                                 <p key={index} className="text-danger d-table">{error}</p>
//                             ))}










                            
//                             <div className="row">
//                                 <div className="col-md-4">
//                                     <Button className="add-btn" onClick={handleAddRow}>
//                                         Add More
//                                     </Button>
//                                 </div>
//                             </div>
//                             <Button className="add-btn" onClick={handleSave}>
//                                 Save
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default Addnewstudy;


































import React, { useEffect, useState } from "react";
import { Button } from "antd";
import axios from "axios";
import { toast } from 'react-toastify';
import Navbar from '../Header/Navbar.jsx'
import { createAddstudy, getStudy, getUsernameByEmail } from '../../Utils/Contancts.js'
import './Addstudy.css'
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";

function Addnewstudy() {
    const [rows, setRows] = useState([0]);
    const [studyName, setStudyName] = useState("");
    const navigate = useNavigate()


    const [rowData, setRowData] = useState([{ visit: "", isCustom: false }]);


const [study,setStudy] = useState([])
  
const [studyNameError, setStudyNameError] = useState("");
const [visitErrors, setVisitErrors] = useState([]);


    const [username, setUsername] = useState("");   
    const userEmail = localStorage.getItem("email");
   console.log("add study user email",userEmail);
   
    const[studies,setStudies] = useState([])

    // useEffect(() => {
    //     const fetchUsername = async () => {
    //         try {
    //             const response = await axios.post(getUsernameByEmail,
    //                 { userEmail }
    //             );
    //             setUsername(response.data.fullname); // Set the retrieved username
    //         } catch (error) {
    //             console.error("Error fetching username:", error);
    //         }
    //     };
        
    //     if (userEmail) {
    //         fetchUsername();
    //     }
    //     }, [userEmail]);
    


        useEffect(() => {
            const fetchStudies = async () => {
              try {
                const response = await axios.get(getStudy);
                setStudies(response.data);
              } catch (error) {
                console.error("Error fetching studies:", error);
              }
            };
          
            fetchStudies();
          }, []);



    const handleAddRow = () => {
        const newRowData = [...rowData];
        newRowData.push({ visit: "", isCustom: false });
        setRowData(newRowData);
        const newRows = [...rows];
        newRows.push(rows.length);
        setRows(newRows);
    };


    
    const handleRemoveRow = (index) => {
        const newRowData = [...rowData];
        newRowData.splice(index, 1);
        setRowData(newRowData);
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
    };

    const handleVisitChange = (index, value) => {
        const newRowData = [...rowData];
        newRowData[index].visit = value;
        newRowData[index].isCustom = value === "custom";
        setRowData(newRowData);
    };

const handleCustomVisitChange = (index, value) => {
    const updatedRowData = [...rowData];
    updatedRowData[index].customVisit = value;
    setRowData(updatedRowData);
};
const validateInputs = () => {
    let isValid = true;
    if (studyName.trim() === "") {
        setStudyNameError("Please enter a study name");
        isValid = false;
    } else {
        setStudyNameError("");
    }

    const errors = [];
    for (let i = 0; i < rows.length; i++) {
        const visit = document.getElementsByName(`visit_${i}`)[0].value;
        const day = document.getElementsByName(`day_${i}`)[0].value;
        const dayunit = document.getElementsByName(`dayunit_${i}`)[0].value;

        if (visit === "---Select Visit---") {
            errors.push(`Please Select Visit`);
            isValid = false;
        }

        if (day === "" || isNaN(day)) {
            errors.push(`* Number`);
            isValid = false;
        }

        if (dayunit === "---Select Day---") {
            errors.push(`* Day`);
            isValid = false;
        }
    }
    setVisitErrors(errors);
    return isValid;
};


const handleSave = async (e) =>{
    e.preventDefault();
  
    
    const studyNames = studies.map(item => ({ studyname: item.studyname, email: item.userEmail }));
  
    // Validate inputs
    if (!validateInputs()) {
      return;
    }

      const details = [];

  for (let i = 0; i < rows.length; i++) {
    // const visit = document.getElementsByName(`visit_${i}`)[0].value;
  
    let visit = document.getElementsByName(`visit_${i}`)[0].value;
    // let customVisit = '';
    if (visit === 'custom') {
        visit = document.getElementsByName(`customVisit_${i}`)[0].value;
    }
  
  
    const day = document.getElementsByName(`day_${i}`)[0].value;
    const dayunit = document.getElementsByName(`dayunit_${i}`)[0].value;
    const minus = document.getElementsByName(`minus_${i}`)[0].value;
    const minusunit = document.getElementsByName(`minusunit_${i}`)[0].value;
    const plus = document.getElementsByName(`plus_${i}`)[0].value;
    const plusunit = document.getElementsByName(`plusunit_${i}`)[0].value;

    details.push({visit, day, dayunit, minus, minusunit, plus, plusunit });
  }
    

const isStudyNameExists = studyNames.some(item => item.studyname === studyName);
    if (isStudyNameExists) {
    toast.success("Study Name Already Exist");
    return;
    }
  
    try {
      const studyResponse = await axios.post(createAddstudy, {
        email: userEmail, 
        studyname: studyName,
        details: details
      });
  
      // Display success message
      toast.success("Study Added Successfully");
      navigate('/mystudies');
      setStudyName(''); 
  
      // Reset input fields
      for (let i = 0; i < rows.length; i++) {
        document.getElementsByName(`visit_${i}`)[0].value = '';
        document.getElementsByName(`day_${i}`)[0].value = '';
        document.getElementsByName(`dayunit_${i}`)[0].value = '';
        document.getElementsByName(`minus_${i}`)[0].value = '';
        document.getElementsByName(`minusunit_${i}`)[0].value = '';
        document.getElementsByName(`plus_${i}`)[0].value = '';
        document.getElementsByName(`plusunit_${i}`)[0].value = '';
      }
  
      // Log success
    //   console.log("Study added successfully:", studyResponse.data);
    } catch (error) {
      // Handle error
      console.error("Error adding study:", error);
    }

  
}

console.log("study name",studies);
      return (
        <>
            <Navbar />
            <div className="mt-10">
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10 bg-light">
                            <h2>Add Study Form</h2>
                            <div className="row">
                                <div className="studyname col-md-2">
                                    <span className="fs-5 text-dark">Study Name</span>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        name="studyname"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Study Name"
                                        value={studyName}
                                        onChange={(e) => setStudyName(e.target.value)}
                                    />
                                      {studyNameError && <p className="text-danger d-table">{studyNameError}</p>}
                           
                                </div>
                            </div>
                    



{rows.map((row, index) => (
    <div className="addmore row mt-3" key={index}>
        <div className="col-md-2">
            <span className="fs-6 text-dark d-table">Visit</span>
            <select
                className="form-control"
                name={`visit_${index}`}
                value={rowData[index].visit}
                onChange={(e) => handleVisitChange(index, e.target.value)}
            >
                <option value="">---Select Visit---</option>
                <option value="Visit 1">Visit 1</option>
                <option value="Visit 2">Visit 2</option>
                <option value="Visit 3">Visit 3</option>
                <option value="Visit 4">Visit 4</option>
                <option value="custom">Custom</option>
            </select>
        </div>
      
        <div className="col-md-1">
            <span className="fs-6 text-dark d-table">Day</span>
            <input
                name={`day_${index}`}
                type="number"
                className="form-control w-75 no-scrollbar"
            />
        </div>
        <div className="col-md-2 mt-4">
            <select className="form-control" name={`dayunit_${index}`}>
                <option>---Select Day---</option>
                <option value="Day">Days</option>
                <option value="Week">Weeks</option>
                <option value="Month">Months</option>
                <option value="Year">Years</option>
            </select>
        </div>
        <div className="col-md-1">
            <span className="fs-6 text-dark d-table">Minus</span>
            <input
                name={`minus_${index}`}
                type="number"
                className="form-control w-75 no-scrollbar"
            />
        </div>
        <div className="col-md-2 mt-4">
            <select className="form-control" name={`minusunit_${index}`}>
                <option>---Select---</option>
                <option value="Day">Days</option>
                <option value="Week">Weeks</option>
                <option value="Month">Months</option>
                <option value="Year">Years</option>
            </select>
        </div>
        <div className="col-md-1">
            <span className="fs-6 text-dark d-table">Plus</span>
            <input
                name={`plus_${index}`}
                type="number"
                className="form-control w-75 no-scrollbar"
            />
        </div>
        <div className="col-md-2 mt-4">
            <select className="form-control" name={`plusunit_${index}`}>
                <option>---Select---</option>
                <option value="Day">Days</option>
                <option value="Week">Weeks</option>
                <option value="Month">Months</option>
                <option value="Year">Years</option>
            </select>
        </div>
        {index !== 0 && (
            <div className="col-md-1 mt-4">
                <button
                    className="remove-btn border-0"
                    onClick={() => handleRemoveRow(index)}
                >
                    <IoClose className="w-100 text-danger" />
                </button>
            </div>
        )}

{rowData[index].visit === 'custom' && (
            <div className="col-md-2">
                <input
                   name={`customVisit_${index}`}
                  
                    type="text"
                    className="form-control"
                    placeholder="Enter Custom Visit"
                    onChange={(e) => handleCustomVisitChange(index, e.target.value)}
                />
            </div>
        )}
    </div>
))}
 {visitErrors.map((error, index) => (
                                <p key={index} className="text-danger d-table">{error}</p>
                            ))}










                            
                            <div className="row">
                                <div className="col-md-4">
                                    <Button className="add-btn" onClick={handleAddRow}>
                                        Add More
                                    </Button>
                                </div>
                            </div>
                            <Button className="add-btn" onClick={handleSave}>
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Addnewstudy;



















