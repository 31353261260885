import React from "react";

import AddStudy from "../AddStudy";
import Navbar from "../Header/Navbar";

function HomePage() {
    return (
  <>
<Navbar/>
<AddStudy/>
</>

    )
}
export default HomePage;
