import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import { Button } from "antd";
import Link from "antd/es/typography/Link";
import axios from "axios";
import Info from "./Userinfo";
import { alluser } from "../../Utils/Contancts";
import Usertable from "./Usertable";

function Userinfo() {
  const [userdata, setUserdata] = useState([]);
  const [search, setSearch] = useState("");
  const [clickedUserData, setClickedUserData] = useState({
    fullName: null,
    email: null,
  });
  const [userInfo, setUserInfo] = useState(null); // State to store user info

  useEffect(() => {
    const fetchStudies = async () => {
      try {
        const response = await axios.get(alluser);
        setUserdata(response.data);
      } catch (error) {
        console.error("Error fetching studies:", error);
      }
    };

    fetchStudies();
  }, []);

  const handleButtonClick = (fullName, email) => {
    setClickedUserData({ fullName, email });
  };

  useEffect(() => {
    // Fetch user info based on username and email
    const fetchUserInfo = async () => {
      if (
        !clickedUserData.fullName ||
        !clickedUserData.email ||
        userdata.length === 0
      )
        return;

      // Find user matching clicked full name and email
      const user = userdata.find(
        (item) =>
          item.fullName === clickedUserData.fullName &&
          item.email === clickedUserData.email
      );
      if (user) {
        setUserInfo(user);
      } else {
        console.log("User not found!");
      }
    };

    fetchUserInfo();
  }, [clickedUserData, userdata]);

  // Filter user data based on search input
  const filteredUserdata = userdata.filter((item) =>
    item.fullName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Navbar />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-4">
                <h4 className="d-table">User Information</h4>
              </div>
              <div className="col-md-6">
                <input
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search By User Name"
                  className="form-control shadow-none border border-secondary rounded float-left"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <ul className="">
                  {filteredUserdata.map((item, index) => (
                    <li className="studylist" key={index}>
                      <Link>
                        <Button
                          className="studybtn bg-light"
                          onClick={() =>
                            handleButtonClick(item.fullName, item.email)
                          }
                        >
                          {item.fullName}
                        </Button>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-8">
                {userInfo && <Info Userinfo={userInfo} />}
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <Usertable />
          </div>
        </div>
      </div>
    </>
  );
}

export default Userinfo;
