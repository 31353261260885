// import { Button, Dropdown } from 'antd';
// import React, { useEffect, useRef, useState } from 'react'
// import { Link} from 'react-router-dom';
// import { deletestudy, getUsernameByEmail } from '../../Utils/Contancts';
// import { toast } from 'react-toastify';
// import axios from 'axios';
// import { MdDelete } from "react-icons/md";

// import {useReactToPrint} from 'react-to-print'

// function Tabledatainfo({subjectinfo}) {

//     const data = subjectinfo
//     // console.log("props data",data);


//     const [search, setSearch] = useState("");
//     const [filteredStudyTable, setFilteredStudyTable] = useState([]);


//     const [username, setUsername] = useState("");   
//     const userEmail = localStorage.getItem("email");
//     // fetch username by email
//     useEffect(() => {
//     const fetchUsername = async () => {
//         try { 
//             const response = await axios.post(getUsernameByEmail,
//                 { userEmail }
//             );
//             setUsername(response.data.fullname); // Set the retrieved username
//         } catch (error) {
//             console.error("Error fetching username:", error);
//         }
//     };
    
//     if (userEmail) {
//         fetchUsername();
//     }
//     }, [userEmail]);


//     const handleDelete = (id)=>{
//         axios.delete(deletestudy+id)
//     .then(res => {console.log(res)
//         toast.success("Deleted Successfully")
//     window.location.reload()
//     })
//     .catch(err => console.log(err))
//       }
      
      
//       const printRef = useRef(null)
//       const handlePrint = useReactToPrint({
//         content: () => printRef.current,
//         });





//       useEffect(() => {
//         const filteredData = data.filter((item) => {
//             const lowerCaseSearch = search.toLowerCase();
//             const lowerCaseStudyname = item.studyname.toLowerCase();
//             const lowerCaseSubjectNo = item.subjectNo.toLowerCase();
    
//             return (
//                 lowerCaseStudyname.includes(lowerCaseSearch) ||
//                 lowerCaseSubjectNo.includes(lowerCaseSearch)
//             );
//         });
    
//         setFilteredStudyTable(filteredData);
//     }, [data, search]);
    
    



// const handledelete = async (id, subject, visit, minus, date, plus) => {
//     try {
        
//         await axios.delete(`https://www.researchschedule.com/delete/${encodeURIComponent(visit)}/${encodeURIComponent(subject)}`);
//         toast.success("Study deleted successfully");
//         window.location.reload()
//         // Update frontend state or trigger a re-fetch of study data if necessary
//     } catch (error) {
//         if (error.response && error.response.status === 404) {
//             toast.error("Study not found. Double-check subject and visit.");
//         } else {
//             console.error("Error deleting study:", error);
//             toast.error("Failed to delete study");
//         }
//     }

// };


//     return (
//     <>   


// {username == "Admin" && (
//            <>
         

// <div className="row">
//     <div className="col-md-9"></div> 
    
//        <div className="col-md-3 d-flex justify-content-end">
       
//         <input
//             placeholder="Search"
//             className="form-control shadow-none border border-secondary rounded"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
            
//         />
//         <Button className='tableprint studybtn' onClick={handlePrint}>Print</Button>

//     </div>
// </div>




//  <div className="col-md-12 mt-3" ref={printRef}>
//  {filteredStudyTable.map((item, outerIndex) => (
// <div key={outerIndex} >
// {username==='Admin' && <p className='d-table fs-5 fw-bold'>User Name: <span className='fs-7 fw-lighter'>{item.username}</span></p>}
        
// <p className='d-table fs-5 fw-bold'>Study Name: <span className='fs-7 fw-lighter'>{item.studyname}</span></p>
// <p className='d-table fs-5 fw-bold'>Subject No: <span className='fs-7 fw-lighter'>{item.subjectNo}</span></p>

// <table className="table">
// <thead>
//   <tr>
//       <th></th>
//       <th>Minus</th>
//       <th>Actual Date</th>
//       <th>Plus</th>
//   </tr>
// </thead>
// <tbody>
//   {item.details && item.details.map((detail, innerIndex) => (
//       <tr key={innerIndex} className="">
//           <td>
//               <div className="col">
//                   <span className="h5 text-dark">{detail.visit}</span>
//               </div>
//           </td>
//           <td>
//               <div className="">
//                   <input placeholder="" type="" value={item.minusDates[detail.visit] || ""}  className="form-control" />
//               </div>
//           </td>
//           <td>
//               <div className="">
//                   <input placeholder="" type="" value={item.actualDate[detail.visit] || ""}  className="form-control" />
//               </div>
//           </td>
//           <td>
//               <div className="">
//                   <input placeholder="" type="" value={item.plusDates[detail.visit] || ""} className="form-control" />
//               </div>
//           </td>
          
//       </tr>
//   ))}
// </tbody>
// </table>

// <Button className='tableprint studybtn' onClick={(e)=> handleDelete(item._id)}>Delete</Button>                          
// <Link to={`/Editsubjectinfo/${item._id}`}><Button className='tableprint studybtn'>Edit</Button></Link>

// </div>
// ))}
// {/* <Button className='tableprint studybtn' onClick={handlePrint}>Print</Button> */}

// </div>
// </>
// )}

  
// {username !== "Admin" && (
//     <div className="col-md-9" ref={printRef}>
//                {data.map((item, outerIndex) => (
//     <div key={outerIndex} >
//         {username==='Admin' && <p className='d-table fs-5 fw-bold'>User Name: <span className='fs-7 fw-lighter'>{item.username}</span></p>}
                      
//         <p className='d-table fs-5 fw-bold'>Study Name: <span className='fs-7 fw-lighter'>{item.studyname}</span></p>
//         <p className='d-table fs-5 fw-bold'>Subject No: <span className='fs-7 fw-lighter'>{item.subjectNo}</span></p>
          
//         <table className="table">
//             <thead>
//                 <tr>
//                     <th></th>
//                     <th>Minus</th>
//                     <th>Actual Date</th>
//                     <th>Plus</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 {item.details && item.details.map((detail, innerIndex) => (
//                     <tr key={innerIndex} className="">
//                         <td>
//                             <div className="col">
//                                 <span className="h5 text-dark">{detail.visit}</span>
//                             </div>
//                         </td>
//                         <td>
//                             <div className="">
//                                 <input placeholder="" type="" value={item.minusDates[detail.visit] || ""}  className="form-control" />
//                             </div>
//                         </td>
//                         <td>
//                             <div className="">
//                                 <input placeholder="" type="" value={item.actualDate[detail.visit] || ""}  className="form-control" />
//                             </div>
//                         </td>
//                         <td>
//                             <div className="">
//                                 <input placeholder="" type="" value={item.plusDates[detail.visit] || ""} className="form-control" />
//                             </div>
//                         </td>
//                         <td>
//                         {/* <button onClick={(e) => { e.preventDefault(); handledelete(detail._id,item.subjectNo,detail.visit,item.minusDates[detail.visit], item.actualDate[detail.visit], item.plusDates[detail.visit] ); }}>delete</button> */}
//                         <MdDelete onClick={(e) => { e.preventDefault(); handledelete(detail._id,item.subjectNo,detail.visit,item.minusDates[detail.visit], item.actualDate[detail.visit], item.plusDates[detail.visit] ); }} className="text-danger h5" /> 
                                           
//                           </td>
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
      
//         <Button className='tableprint studybtn' onClick={(e)=> handleDelete(item._id)}>Delete</Button>                          
//     <Link to={`/Editsubjectinfo/${item._id}`}><Button className='tableprint studybtn'>Edit</Button></Link>
    
//     </div>
// ))}
//    <Button className='tableprint studybtn' onClick={handlePrint}>Print</Button>
         
//     </div>
// )}
// </>
//   )
// }

// export default Tabledatainfo

























import { Button, Dropdown } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { Link} from 'react-router-dom';
import { deletestudy, getUsernameByEmail } from '../../Utils/Contancts';
import { toast } from 'react-toastify';
import axios from 'axios';
import { MdDelete } from "react-icons/md";

import {useReactToPrint} from 'react-to-print'

function Tabledatainfo({subjectinfo}) {

    const data = subjectinfo
    // console.log("props data",data);


    const [search, setSearch] = useState("");
    const [filteredStudyTable, setFilteredStudyTable] = useState([]);


    const [username, setUsername] = useState("");   
  
    const userEmail = localStorage.getItem("email");
    // fetch username by email
    // useEffect(() => {
    // const fetchUsername = async () => {
    //     try { 
    //         const response = await axios.post(getUsernameByEmail,
    //             { userEmail }
    //         );
    //         setUsername(response.data.fullname); // Set the retrieved username
    //     } catch (error) {
    //         console.error("Error fetching username:", error);
    //     }
    // };
    
    // if (userEmail) {
    //     fetchUsername();
    // }
    // }, [userEmail]);


    const handleDelete = (id)=>{
        axios.delete(deletestudy+id)
    .then(res => {console.log(res)
        toast.success("Deleted Successfully")
    window.location.reload()
    })
    .catch(err => console.log(err))
      }
      
      
      const printRef = useRef(null)
      const handlePrint = useReactToPrint({
        content: () => printRef.current,
        });





      useEffect(() => {
        const filteredData = data.filter((item) => {
            const lowerCaseSearch = search.toLowerCase();
            const lowerCaseStudyname = item.studyname.toLowerCase();
            const lowerCaseSubjectNo = item.subjectNo.toLowerCase();
    
            return (
                lowerCaseStudyname.includes(lowerCaseSearch) ||
                lowerCaseSubjectNo.includes(lowerCaseSearch)
            );
        });
    
        setFilteredStudyTable(filteredData);
    }, [data, search]);
    
    



const handledelete = async (id, subject, visit, minus, date, plus) => {
    try {
        
        await axios.delete(`https://www.researchschedule.com/delete/${encodeURIComponent(visit)}/${encodeURIComponent(subject)}`);
        toast.success("Study deleted successfully");
        window.location.reload()
        // Update frontend state or trigger a re-fetch of study data if necessary
    } catch (error) {
        if (error.response && error.response.status === 404) {
            toast.error("Study not found. Double-check subject and visit.");
        } else {
            console.error("Error deleting study:", error);
            toast.error("Failed to delete study");
        }
    }

};


    return (
    <>   


{userEmail == "admin@mdfirstresearch.com" && (
           <>
         

<div className="row">
    <div className="col-md-9"></div> 
    
       <div className="col-md-3 d-flex justify-content-end">
       
        <input
            placeholder="Search"
            className="form-control shadow-none border border-secondary rounded"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            
        />
        <Button className='tableprint studybtn' onClick={handlePrint}>Print</Button>

    </div>
</div>




 <div className="col-md-12 mt-3" ref={printRef}>
 {filteredStudyTable.map((item, outerIndex) => (
<div key={outerIndex} >
{userEmail==='admin@mdfirstresearch.com' && <p className='d-table fs-5 fw-bold'>User Name: <span className='fs-7 fw-lighter'>{item.email}</span></p>}
        
<p className='d-table fs-5 fw-bold'>Study Name: <span className='fs-7 fw-lighter'>{item.studyname}</span></p>
<p className='d-table fs-5 fw-bold'>Subject No: <span className='fs-7 fw-lighter'>{item.subjectNo}</span></p>

<table className="table">
<thead>
  <tr>
      <th></th>
      <th>Minus</th>
      <th>Actual Date</th>
      <th>Plus</th>
  </tr>
</thead>
<tbody>
  {item.details && item.details.map((detail, innerIndex) => (
      <tr key={innerIndex} className="">
          <td>
              <div className="col">
                  <span className="h5 text-dark">{detail.visit}</span>
              </div>
          </td>
          <td>
              <div className="">
                  <input placeholder="" type="" value={item.minusDates[detail.visit] || ""}  className="form-control" />
              </div>
          </td>
          <td>
              <div className="">
                  <input placeholder="" type="" value={item.actualDate[detail.visit] || ""}  className="form-control" />
              </div>
          </td>
          <td>
              <div className="">
                  <input placeholder="" type="" value={item.plusDates[detail.visit] || ""} className="form-control" />
              </div>
          </td>
          
      </tr>
  ))}
</tbody>
</table>

<Button className='tableprint studybtn' onClick={(e)=> handleDelete(item._id)}>Delete</Button>                          
<Link to={`/Editsubjectinfo/${item._id}`}><Button className='tableprint studybtn'>Edit</Button></Link>

</div>
))}
{/* <Button className='tableprint studybtn' onClick={handlePrint}>Print</Button> */}

</div>
</>
)}

  
{userEmail !== "admin@mdfirstresearch.com" && (
    <div className="col-md-9" ref={printRef}>
               {data.map((item, outerIndex) => (
    <div key={outerIndex} >
        {userEmail==='admin@mdfirstresearch.com' && <p className='d-table fs-5 fw-bold'>User Name: <span className='fs-7 fw-lighter'>{item.email}</span></p>}
                      
        <p className='d-table fs-5 fw-bold'>Study Name: <span className='fs-7 fw-lighter'>{item.studyname}</span></p>
        <p className='d-table fs-5 fw-bold'>Subject No: <span className='fs-7 fw-lighter'>{item.subjectNo}</span></p>
          
        <table className="table">
            <thead>
                <tr>
                    <th></th>
                    <th>Minus</th>
                    <th>Actual Date</th>
                    <th>Plus</th>
                </tr>
            </thead>
            <tbody>
                {item.details && item.details.map((detail, innerIndex) => (
                    <tr key={innerIndex} className="">
                        <td>
                            <div className="col">
                                <span className="h5 text-dark">{detail.visit}</span>
                            </div>
                        </td>
                        <td>
                            <div className="">
                                <input placeholder="" type="" value={item.minusDates[detail.visit] || ""}  className="form-control" />
                            </div>
                        </td>
                        <td>
                            <div className="">
                                <input placeholder="" type="" value={item.actualDate[detail.visit] || ""}  className="form-control" />
                            </div>
                        </td>
                        <td>
                            <div className="">
                                <input placeholder="" type="" value={item.plusDates[detail.visit] || ""} className="form-control" />
                            </div>
                        </td>
                        <td>
                        {/* <button onClick={(e) => { e.preventDefault(); handledelete(detail._id,item.subjectNo,detail.visit,item.minusDates[detail.visit], item.actualDate[detail.visit], item.plusDates[detail.visit] ); }}>delete</button> */}
                        <MdDelete onClick={(e) => { e.preventDefault(); handledelete(detail._id,item.subjectNo,detail.visit,item.minusDates[detail.visit], item.actualDate[detail.visit], item.plusDates[detail.visit] ); }} className="text-danger h5" /> 
                                           
                          </td>
                    </tr>
                ))}
            </tbody>
        </table>
      
        <Button className='tableprint studybtn' onClick={(e)=> handleDelete(item._id)}>Delete</Button>                          
    <Link to={`/Editsubjectinfo/${item._id}`}><Button className='tableprint studybtn'>Edit</Button></Link>
    
    </div>
))}
   {/* <Button className='tableprint studybtn' onClick={handlePrint}>Print</Button> */}
         
    </div>
)}
</>
  )
}

export default Tabledatainfo
