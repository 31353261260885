import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css"
import axios from "axios";
import { toast } from "react-toastify";
import {createlogout, getUsernameByEmail} from '../../Utils/Contancts'

function Navbar() {

    const [username, setUsername] = useState("");   
    const userEmail = localStorage.getItem("email");
console.log("email",userEmail);


    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const [isLoggedOut, setIsLoggedOut] = useState(false);

   
   const handleLogout = async() => {
    try{
       await axios.post(createlogout);
       toast.success("Logged out successfully");
       setIsLoggedOut(true);
       navigate("/")
    }

    catch(error){
        console.error("Error logging out:", error);
        toast.error("Error logging out. Please try again.");
    }
   }


   useEffect(() => {
    const timer = setTimeout(() => {
        if (isLoggedOut) {
            console.log("logout", document.cookie);
            
        }
    }, 1000); // Delay for 1 second 

    return () => clearTimeout(timer);
}, [isLoggedOut]);



// fetch username by email
useEffect(() => {
    const fetchUsername = async () => {
        try {
            const response = await axios.post(getUsernameByEmail,
                { userEmail }
            );
            setUsername(response.data.fullname); // Set the retrieved username
        } catch (error) {
            console.error("Error fetching username:", error);
        }
    };

    if (userEmail) {
        fetchUsername();
    }
}, [userEmail]);



  return (
   <>
    <div>

<nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between h-25">


    <div className="username">
    
        <p className="mt-3"><span><i className="fa-solid fa-user"></i></span> Welcome: 
        
        <strong>{username}</strong>
        </p>


    </div>



    <div className="d-flex justify-content-end">
        <div className="links d-flex flex-row justify-content-between">
            <Link to="/login"
                className={currentPath === "/" ? "active" : ""}>
            </Link>

            <Link to="/homepage"
                className={currentPath === "/homepage" ? "active" : ""}>
                Home
            </Link>

           
            <Link onClick={handleLogout}>
                Logout
            </Link>

        </div>
        <div className="icons pr-10 pt-1">
            <i className="fa-brands fa-facebook"></i>
            <i className="fa-brands fa-twitter"></i>
            <i className="fa-brands fa-google-plus-g"></i>
            <i className="fa-brands fa-vimeo-v"></i>
            <i className="fa-brands fa-youtube"></i>
        </div>
          </div>
</nav>

<div className="contact d-flex flex-row justify-content-between border-top  bg-white h-25">
    <p className=" ml-10 h4 font-bold">
           <a href="/homepage"> <img src="favi.png" alt="" className='' /></a>
           Research Center
        
    </p>
  
</div>

<div>
   
</div>
</div>
   </>
  )
}

export default Navbar
