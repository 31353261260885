// import { Button } from "antd";
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { AiOutlinePlus } from "react-icons/ai";
// import { AiOutlineMinus } from "react-icons/ai";
// import { IoClose } from "react-icons/io5";

// import { toast } from 'react-toastify';
// import Navbar from '../Header/Navbar.jsx'
// import {createAddstudy, getstudybyid, getstudydatabyid, update} from '../../Utils/Contancts.js'
// import { useNavigate, useParams } from "react-router-dom";
// // import './Addstudy.css'
// import moment from "moment";


// function Editsubjectinfo() {
//     const {id} = useParams()
//     const navigate = useNavigate();

//     const [rows, setRows] = useState([0]);
//     const [studyName, setStudyName] = useState("");
     
//     const [username, setUsername] = useState("");   
//     const userEmail = localStorage.getItem("email");
    
//     const [data,setData] = useState([])
//     const [subjectno,setSubjectno] = useState("");

//     const [plusnum, setPlusnum] = useState({});
//     const [minusnum, setMinusnum] = useState({});
//     const [plusDates, setPlusDates] = useState({});
// const [minusDates, setMinusDates] = useState({});
// const [actualDate,setActualdate] = useState({});
// const [referenceDate, setReferenceDate] = useState("");





//     // fetch username by email
//     useEffect(() => {
//     const fetchUsername = async () => {
//         try {
//             const response = await axios.post("http://localhost:4000/getUsernameByEmail",
//                 { userEmail }
//             );
//             setUsername(response.data.fullname); // Set the retrieved username
//         } catch (error) {
//             console.error("Error fetching username:", error);
//         }
//     };
    
//     if (userEmail) {
//         fetchUsername();
//     }
//     }, [userEmail]);




//     const handleAddRow = () => {
//         const newRows = [...rows];
//         newRows.push(rows.length);
//         setRows(newRows);
//     };

//     const handleRemoveRow = (index) => {
//         const newRows = [...rows];
//         newRows.splice(index, 1);
//         setRows(newRows);
//     };


//     // useEffect(() => {
//     //     const fetchData = async () => {
//     //         try {
//     //             const result = await axios.get(getstudydatabyid+id);
//     //             setData(result.data);
    
//     //         } catch (error) {
//     //             console.error('Error fetching data:', error);
//     //         }
//     //     };
    
//     //     fetchData();
    
//     //     return () => {};
//     // }, [id]);






//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const result = await axios.get(getstudydatabyid+id);
//                 setData(result.data);
//                 setSubjectno(result.data.subjectNo)
//                 setPlusDates(result.data.plusDates)
//                 setMinusDates(result.data.minusDates)
//                 setActualdate(result.data.actualDate)

//                 if (result.data && result.data.details && result.data.details.length > 0) {
//                     const plusValues = {};
//                     const minusValues = {};
//                     result.data.details.forEach(detail => {
//                         plusValues[detail.visit] = {
//                            day:detail.day,
//                            dayunit:detail.dayunit,
//                             plus: detail.plus,
//                             plusunit: detail.plusunit
//                         };
//                         minusValues[detail.visit] = {
//                             minus: detail.minus,
//                             minusunit: detail.minusunit
//                         };
//                     });
//                     setPlusnum(plusValues);
//                     setMinusnum(minusValues);
//                     // console.log("plus number",plusnum);
//                     // console.log("MINUS nUMBER", minusnum);
    
//                     }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };
    
//         fetchData();
    
//         return () => {};
//     }, [id]);
    
    
    
    
    
// const handleUpdate = () => {
//     try {
//         const newPlusDates = {};
//         const newMinusDates = {};
//         const newActualDate = {};

//         if (plusnum && Object.keys(plusnum).length) {
//             Object.entries(plusnum).forEach(([visit, { day, dayunit }]) => {
//                 const plusNumber = Number(day);
//                 if (plusNumber !== 0) {
//                     let refDate = moment(referenceDate); // Take reference date for each visit
//                     let visitDate;
//                     if (dayunit === "Year") {
//                         visitDate = refDate.add(plusNumber, "years").format("YYYY-MM-DD");
//                     } else if (dayunit === "Month") {
//                         visitDate = refDate.add(plusNumber, "months").format("YYYY-MM-DD");
//                     } else if (dayunit === "Week") {
//                         visitDate = refDate.add(plusNumber * 7, "days").format("YYYY-MM-DD");
//                     } else {
//                         visitDate = refDate.add(plusNumber, "days").format("YYYY-MM-DD");
//                     }
//                    newActualDate[visit] = visitDate;
//                 }
//             });
//         }

//         if (plusnum && Object.keys(plusnum).length) {
//             Object.entries(plusnum).forEach(([visit, { plus, plusunit }]) => {
//                 const plusNumber = Number(plus);
//                 if (plusNumber !== 0) {
//                     let refDate = moment(referenceDate); // Take reference date for each visit
//                     let visitDate;
//                     if (plusunit === "Year") {
//                         visitDate = refDate.add(plusNumber, "years").format("YYYY-MM-DD");
//                     } else if (plusunit === "Month") {
//                         visitDate = refDate.add(plusNumber, "months").format("YYYY-MM-DD");
//                     } else if (plusunit === "Week") {
//                         visitDate = refDate.add(plusNumber * 7, "days").format("YYYY-MM-DD");
//                     } else {
//                         visitDate = refDate.add(plusNumber, "days").format("YYYY-MM-DD");
//                     }
//                     newPlusDates[visit] = visitDate;
//                 }
//             });
//         }
//         if (minusnum && Object.keys(minusnum).length) {
//             Object.entries(minusnum).forEach(([visit, { minus, minusunit }]) => {
//                 const minusNumber = Number(minus);
//                 if (minusNumber !== 0) {
//                     let refDate = moment(referenceDate); // Take reference date for each visit
//                     let visitDate;
//                     if (minusunit === "Year") {
//                         visitDate = refDate.subtract(minusNumber, "years").format("YYYY-MM-DD");
//                     } else if (minusunit === "Month") {
//                         visitDate = refDate.subtract(minusNumber, "months").format("YYYY-MM-DD");
//                     } else if (minusunit === "Week") {
//                         visitDate = refDate.subtract(minusNumber * 7, "days").format("YYYY-MM-DD");
//                     } else {
//                         visitDate = refDate.subtract(minusNumber, "days").format("YYYY-MM-DD");
//                     }
//                     newMinusDates[visit] = visitDate;
//                 }
//             });
//         }

//         setPlusDates(newPlusDates);
//         setMinusDates(newMinusDates);
//         setActualdate(newActualDate)
//         // console.log("Plus Dates:", newPlusDates);
//         // console.log("Minus Dates:", newMinusDates);

//     } catch (error) {
//         console.error("Error occurred:", error);
//     }
// }
    

// const handlesave = async (e) => {
   
//     try {
//             // Update study by ID
//             await axios.put(update+id, {
//                 subjectNo: subjectno,
//                 // referenceDate: referenceDate,
//                 plusDates: plusDates, 
//                 minusDates: minusDates,      
//                 actualDate:actualDate    
//             });
//             toast.success("Data Updated Successfully");
//             const result = await axios.get(getstudybyid+id);
//             // setData(result.data);
//             // window.location.reload()
//           navigate('/tabledata')
          
//             // console.log("Plus Dates:", plusDates);
//             // console.log("Minus Dates:", minusDates);
    
//         } catch (error) {
//             console.error("Error occurred:", error);
//         }
//     }


  


// // console.log("data dasdadx",data);
// console.log("minus num",minusnum);
// console.log("plus num",plusnum);
// console.log("minus date",minusDates);
// console.log("actual date",actualDate);
// console.log("plus date",plusDates);





//     return (
//         <>
//      <Navbar/>

//         <div className="mt-10">
//             <div className="container-fluid">
//                 <div className="row d-flex justify-content-center">
//                     <div className="col-md-10 bg-light">
//                         <h2>Edit Study Form</h2>
//                         <div className="row">
//                             <div className="studyname col-md-2">
//                                 <span className="fs-5 text-dark">Study Name</span>
//                             </div>
//                             <div className="col-md-8">
//                                 <input
//                                   name="studyname"
//                                   type="text"
//                                     className="form-control"
//                                     placeholder="Enter Study Name"
//                                     value={data.studyname}
//                                 />
//                             </div>
//                             <div className="studyname col-md-2">
//                                 <span className="fs-5 text-dark">Subject Number</span>
//                             </div>
//                             <div className="col-md-8">
//                                 <input
//                                   name="studyname"
//                                   type="text"
//                                     className="form-control"
//                                     placeholder="Subject Number"
//                                     value={subjectno}
//                                     onChange={(e) => setSubjectno(e.target.value)}
                                       
//                               />
//                             </div>

//                             <div className="row ">
//                                     <div className="col-md-3 ">
//                                         <span className="fs-5 text-dark d-table">Reference Date</span>
//                                         <input
//                                             type="date"
//                                             placeholder="Please Select Reference Date"
//                                             className="form-control"
//                                             value={referenceDate}
//                                             onChange={(e) => setReferenceDate(e.target.value)}
                                                  
//                                         />
//                                     </div>

                                
                                 
//                                     <div className="col-md-3 mt-2">
//                                         <Button
//                                             className="add-btn" onClick={() => handleUpdate()}>
//                                             Save
//                                         </Button>
//                                     </div>
//                                 </div>

//                             {/* {data.details && data.details.map((detail, innerIndex) => (     
//                         <div className="addmore row mt-3">
//                                 <div className="col-md-2">
                                   
//                                     <span className="fs-6 text-dark d-table">Visit</span>
//                                     <select className="form-control">
//                                         <option>{detail.visit}</option>
//                                         <option>Visit 1</option>
//                                         <option>Visit 2</option>
//                                         <option>Visit 3</option>
//                                         <option>Visit 4</option>
//                                     </select>
//                                 </div>
//                                 <div className="col-md-1">
//                                 <span className="fs-6 text-dark d-table">Day</span>
//                                     <input
                                        
//                                        type="number"
//                                         className="form-control w-75 no-scrollbar"
//                                    value={detail.day}
//                                    />
//                                 </div>
//                                 <div className="col-md-2 mt-4">
//                                     <select className="form-control">
//                                         <option>{detail.dayunit}</option>
//                                         <option value="Day">Days</option>
//                                         <option value="Week">Weeks</option>
//                                         <option value="Month">Months</option>
//                                         <option value="Year">Years</option>
//                                     </select>
//                                 </div>
//                                 <div className="col-md-1">
//                                 <span className="fs-6 text-dark d-table">Minus</span>
//                                     <input
                                       
                                        
//                                         type="number"
//                                         className="form-control w-75 no-scrollbar"
//                                         value={detail.minus}
//                                     />
//                                 </div>
//                                 <div className="col-md-2 mt-4">
//                                     <select className="form-control" >
//                                         <option>{detail.minusunit}</option>
//                                         <option value="Day">Days</option>
//                                         <option value="Week">Weeks</option>
//                                         <option value="Month">Months</option>
//                                         <option value="Year">Years</option>
//                                     </select>
//                                 </div>
//                                 <div className="col-md-1">
//                                 <span className="fs-6 text-dark d-table">Plus</span>
//                                     <input
                                  
//                                         type="number"
//                                         className="form-control w-75 no-scrollbar"
//                                         value={detail.plus}
//                                     />
//                                   </div>
//                                   <div className="col-md-2 mt-4">
//                                     <select className="form-control" >
//                                         <option>{detail.plusunit}</option>
//                                         <option value="Day">Days</option>
//                                         <option value="Week">Weeks</option>
//                                         <option value="Month">Months</option>
//                                         <option value="Year">Years</option>
//                                     </select>
//                                 </div>

                                  
//                                     <div className="col-md-1 mt-4">
//                                         <Button
//                                             className="remove-btn border-0"
//                                         >
//                                             <IoClose className="w-100 text-danger" />

//                                         </Button>
//                                     </div>
                               
//                             </div>
//                         ))} */}




//                             <table className="table">
//             <thead>
//                 <tr>
//                     <th></th>
//                     <th>Minus</th>
//                     <th>Actual Date</th>
//                     <th>Plus</th>
//                 </tr>
//             </thead>
//             <tbody>
//             {data.details && data.details.map((detail, innerIndex) => (
                
//                     <tr  className="">
//                         <td>
//                             <div className="col">
//                                 <span className="h5 text-dark">{detail.visit}</span>
//                             </div>
//                         </td>
//                         <td>
//                             <div className="">
//                                 <input placeholder="" type="" value={minusDates[detail.visit]}  className="form-control" />
//                             </div>
//                         </td>
//                         <td>
//                             <div className="">
//                                 <input placeholder="" type="" value={actualDate[detail.visit]}  className="form-control" />
//                             </div>
//                         </td>
//                         <td>
//                             <div className="">
//                                 <input placeholder="" type="" value={plusDates[detail.visit]} className="form-control" />
//                             </div>
//                         </td>
//                     </tr>
//                ))}
//             </tbody>
//                             </table>

//                         </div>   
                      


//                         <div className="row">
//                                     <div className="col">
//                                         <Button
//                                             className="add-btn"  onClick={() => handlesave()}
//                                       >
//                                             Update
//                                         </Button>
//                                     </div>
//                                 </div>
//                         {/* {rows.map((row, index) => (
//                             <div className="addmore row mt-3" key={index}>
//                                 <div className="col-md-2">
                                   
//                                     <span className="fs-6 text-dark d-table">Visit</span>
//                                     <select className="form-control" name={`visit_${index}`}>
//                                         <option>---Select Visit---</option>
//                                         <option>Visit 1</option>
//                                         <option>Visit 2</option>
//                                         <option>Visit 3</option>
//                                         <option>Visit 4</option>
//                                     </select>
//                                 </div>
//                                 <div className="col-md-1">
//                                 <span className="fs-6 text-dark d-table">Day</span>
//                                     <input
//                                         name={`day_${index}`}
                                       
//                                         type="number"
//                                         className="form-control w-75 no-scrollbar"
//                                     />
//                                 </div>
//                                 <div className="col-md-2 mt-4">
//                                     <select className="form-control" name={`dayunit_${index}`}>
//                                         <option>---Select Day---</option>
//                                         <option value="Day">Days</option>
//                                         <option value="Week">Weeks</option>
//                                         <option value="Month">Months</option>
//                                         <option value="Year">Years</option>
//                                     </select>
//                                 </div>
//                                 <div className="col-md-1">
//                                 <span className="fs-6 text-dark d-table">Minus</span>
//                                     <input
//                                         name={`minus_${index}`}
                                        
//                                         type="number"
//                                         className="form-control w-75 no-scrollbar"
//                                     />
//                                 </div>
//                                 <div className="col-md-2 mt-4">
//                                     <select className="form-control" name={`minusunit_${index}`}>
//                                         <option>---Select---</option>
//                                         <option value="Day">Days</option>
//                                         <option value="Week">Weeks</option>
//                                         <option value="Month">Months</option>
//                                         <option value="Year">Years</option>
//                                     </select>
//                                 </div>
//                                 <div className="col-md-1">
//                                 <span className="fs-6 text-dark d-table">Plus</span>
//                                     <input
//                                         name={`plus_${index}`}
                                  
//                                         type="number"
//                                         className="form-control w-75 no-scrollbar"
//                                     />
//                                   </div>
//                                   <div className="col-md-2 mt-4">
//                                     <select className="form-control" name={`plusunit_${index}`}>
//                                         <option>---Select---</option>
//                                         <option value="Day">Days</option>
//                                         <option value="Week">Weeks</option>
//                                         <option value="Month">Months</option>
//                                         <option value="Year">Years</option>
//                                     </select>
//                                 </div>

                                  
//                                 {index !== 0 && (
//                                     <div className="col-md-1 mt-4">
//                                         <Button
//                                             className="remove-btn border-0"
//                                             onClick={() => handleRemoveRow(index)}
//                                         >
//                                             <IoClose className="w-100 text-danger" />

//                                         </Button>
//                                     </div>
//                                 )}
//                             </div>
//                         ))} */}
                        
//                         {/* <div className="row">
//                             <div className="col-md-4">
//                                 <Button className="add-btn" onClick={handleAddRow}>
//                                     Add More <AiOutlinePlus />
//                                 </Button>
//                             </div>
//                         </div> */}
                      
//                         {/* <Button className="add-btn" >
//                             Save
//                         </Button> */}
                        
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </>
//     );
// }

// export default Editsubjectinfo;









import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Navbar from "../Header/Navbar";
import {getUsernameByEmail, getstudybyid, getstudydatabyid, update, updatestudybyid} from '../../Utils/Contancts'
import moment from "moment";
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

function Editsubjectinfo() {
    const navigate = useNavigate();

    const [showTable, setShowTable] = useState(false);

const [username, setUsername] = useState("");   
const userEmail = localStorage.getItem("email");

const {id} = useParams()
const [data, setData] = useState(null);
const [plusnum, setPlusnum] = useState({});
const [minusnum, setMinusnum] = useState({});
const [referenceDate, setReferenceDate] = useState("");

const [studyname,setStudyname] = useState("");
const [subjectno,setSubjectno] = useState("");
const [plusDates, setPlusDates] = useState({});
const [minusDates, setMinusDates] = useState({});
const [actualDate,setActualdate] = useState({});


const [subjectnoError, setSubjectnoError] = useState("");
const [referenceDateErrors, setReferenceDateErrors] = useState();
const [selectedDate, setSelectedDate] = useState(null);

// fetch username by email
useEffect(() => {
const fetchUsername = async () => {
    try {
        const response = await axios.post(getUsernameByEmail,
            { userEmail }
        );
        setUsername(response.data.fullname); // Set the retrieved username
    } catch (error) {
        console.error("Error fetching username:", error);
    }
};

if (userEmail) {
    fetchUsername();
}
}, [userEmail]);








  useEffect(() => {
    const fetchData = async () => {
        try {
            const result = await axios.get(getstudydatabyid+id);
            setData(result.data);
            setSubjectno(result.data.subjectNo)
            setStudyname(result.data.studyname)
setPlusDates(result.data.plusDates)
setMinusDates(result.data.minusDates)
setActualdate(result.data.actualDate)

            if (result.data && result.data.details && result.data.details.length > 0) {
                const plusValues = {};
                const minusValues = {};
                result.data.details.forEach(detail => {
                    plusValues[detail.visit] = {
                       day:detail.day,
                       dayunit:detail.dayunit,
                        plus: detail.plus,
                        plusunit: detail.plusunit
                    };
                    minusValues[detail.visit] = {
                        minus: detail.minus,
                        minusunit: detail.minusunit
                    };
                });
                setPlusnum(plusValues);
                setMinusnum(minusValues);
                // console.log("plus number",plusnum);
                // console.log("MINUS nUMBER", minusnum);

                }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();

    return () => {};
}, [id]);


// console.log("data edit",data);
// console.log("plus num",plusDates);

const validateInputs = () => {
    let isValid = true;
    if (subjectno.trim() === "") {
        setSubjectnoError("Please enter a Subject Number");
        isValid = false;
    } else {
        setSubjectnoError("");
    }

    if (referenceDate.trim() === "") {
        setReferenceDateErrors("Please select a Reference Date");
        isValid = false;
    } else {
        setReferenceDateErrors("");
    }

    return isValid;
};



const handleUpdate = () => {
    if (!validateInputs()) {
        return;
    }
    try {
        setShowTable(true);
        const newPlusDates = {};
        const newMinusDates = {};
        const newActualDate = {};

        if (plusnum && Object.keys(plusnum).length) {
            Object.entries(plusnum).forEach(([visit, { day, dayunit }]) => {
                const plusNumber = Number(day);
                if (plusNumber !== 0) {
                    let refDate = moment(referenceDate); // Take reference date for each visit
                    let visitDate;
                    if (dayunit === "Year") {
                        visitDate = refDate.add(plusNumber, "years").format("MM-DD-YYYY");
                    } else if (dayunit === "Month") {
                        visitDate = refDate.add(plusNumber, "months").format("MM-DD-YYYY");
                    } else if (dayunit === "Week") {
                        visitDate = refDate.add(plusNumber * 7, "days").format("MM-DD-YYYY");
                    } else {
                        visitDate = refDate.add(plusNumber, "days").format("MM-DD-YYYY");
                    }
                   newActualDate[visit] = visitDate;
                }
            });
        }

        if (plusnum && Object.keys(plusnum).length) {
            Object.entries(plusnum).forEach(([visit, { plus, plusunit }]) => {
                const plusNumber = Number(plus);
                if (plusNumber !== 0) {
                    let refDate = moment(referenceDate); // Take reference date for each visit
                    let visitDate;
                    if (plusunit === "Year") {
                        visitDate = refDate.add(plusNumber, "years").format("MM-DD-YYYY");
                    } else if (plusunit === "Month") {
                        visitDate = refDate.add(plusNumber, "months").format("MM-DD-YYYY");
                    } else if (plusunit === "Week") {
                        visitDate = refDate.add(plusNumber * 7, "days").format("MM-DD-YYYY");
                    } else {
                        visitDate = refDate.add(plusNumber, "days").format("MM-DD-YYYY");
                    }
                    newPlusDates[visit] = visitDate;
                }
            });
        }
        if (minusnum && Object.keys(minusnum).length) {
            Object.entries(minusnum).forEach(([visit, { minus, minusunit }]) => {
                const minusNumber = Number(minus);
                if (minusNumber !== 0) {
                    let refDate = moment(referenceDate); // Take reference date for each visit
                    let visitDate;
                    if (minusunit === "Year") {
                        visitDate = refDate.subtract(minusNumber, "years").format("MM-DD-YYYY");
                    } else if (minusunit === "Month") {
                        visitDate = refDate.subtract(minusNumber, "months").format("MM-DD-YYYY");
                    } else if (minusunit === "Week") {
                        visitDate = refDate.subtract(minusNumber * 7, "days").format("MM-DD-YYYY");
                    } else {
                        visitDate = refDate.subtract(minusNumber, "days").format("MM-DD-YYYY");
                    }
                    newMinusDates[visit] = visitDate;
                }
            });
        }

        setPlusDates(newPlusDates);
        setMinusDates(newMinusDates);
        setActualdate(newActualDate)
        // console.log("Plus Dates:", newPlusDates);
        // console.log("Minus Dates:", newMinusDates);

    } catch (error) {
        console.error("Error occurred:", error);
    }
}





 const handlesave = async (e) => {
    if (!validateInputs()) {
        return;
    }
    try {
            // Update study by ID
            await axios.put(update+id, {
                subjectNo: subjectno,
                // referenceDate: referenceDate,
                plusDates: plusDates, 
                minusDates: minusDates,      
                actualDate:actualDate    
            });
                toast.success("Data Updated Successfully");
            const result = await axios.get(getstudybyid+id);
            // setData(result.data);
            // window.location.reload()
          navigate('/tabledata')
          
            // console.log("Plus Dates:", plusDates);
            // console.log("Minus Dates:", minusDates);
    
        } catch (error) {
            console.error("Error occurred:", error);
        }

// console.log("updated clicked",id);
// console.log(subjectno);
// console.log("plus date",plusDates);
// console.log("minus date",minusDates);
// console.log("actual date",actualDate);

    }

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setReferenceDate(format(date, 'MM-dd-yyyy'));
      };
// console.log("data updated",data);
  return (
   <>  
   <Navbar/>
    <div className="d-flex justify-content-center">
                <div className="col-md-9 bg-light  p-3">
                    <div className="row">
                        <div className="col-md-12">
                            <form>
                                <div className="col ">
                                    <div className="col-md-6">
                                        <span className="fs-5 text-dark d-table" >{studyname}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="subject No"
                                           value={subjectno}
                                           onChange={(e) => setSubjectno(e.target.value)}
                                        />
                                          {subjectnoError && <p className="text-danger d-table">{subjectnoError}</p>}
                                    </div>
                                </div>
                                <br />
                                <div className="row ">
                                    <div className="col-md-3 ">
                                        <span className="fs-5 text-dark d-table">Reference Date</span>
                                        {/* <input
                                            type="date"
                                            placeholder="Please Select Reference Date"
                                            className="form-control"
                                            value={referenceDate}
                                            onChange={(e) => setReferenceDate(e.target.value)}
                                            
                                        /> */}
                                          
                                          <DatePicker
                     placeholder="Please Select Reference Date"
                     className="form-control"
                                                                             
      selected={selectedDate}
      onChange={(e)=> handleDateChange(e)}
      dateFormat="MM-dd-yyyy" 
    />
                                          
                                          
                                           {referenceDateErrors && <p className="text-danger d-table">{referenceDateErrors}</p>}
                                    </div>

                                
                                 
                                    <div className="col-md-3 mt-2">
                                        <Button
                                            className="add-btn" onClick={() => handleUpdate()}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                                <br />
                                {/* {showTable && (    */}
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Minus</th>
                                            <th>Actual Date</th>
                                            <th>Plus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {data && data.details && data.details.map((detail, index) => (
                                            <tr className="">
                                                <td>
                                                    <div className="col">
                                                        <span className="h5 text-dark">{detail.visit}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <input placeholder="" type="" value={minusDates[detail.visit] || ""} className="form-control"></input>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        
                                                        <input placeholder="" type="" value={actualDate[detail.visit] || ""}  className="form-control"></input>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        
                                                        <input placeholder="" type="" value={plusDates[detail.visit] || ""}  className="form-control"></input>
                                                    </div>
                                                </td>
                                            </tr>
                                            ))}
                                    </tbody>
                                </table>
                              {/* )} */}

                                <br />
                                <div className="row">
                                    <div className="col">
                                        <Button
                                            className="add-btn"  onClick={() => handlesave()}
                                      >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                             
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </>

  )
}

export default Editsubjectinfo
