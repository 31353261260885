import React from 'react';
import { BrowserRouter as Router, Routes, Route,  } from 'react-router-dom';
import './App.css';
import SignUp from './Pages/SignUp';
import HomePage from './Components/HomePage';
import Login from './Pages/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import Addnewstudy from './Components/AddnewStudy';
import Mystudies from './Components/MyStudies';
import StudiesData from './Components/StudiesData';
import ForgotPass from './Components/Forgotpassword';
import ResetPass from './Components/Resetpassword';
import TableData from './Components/TableData';
import Editstudy from './Components/Editstudy';
import Editsubjectinfo from './Components/Editstudy/Editstudy';
import Userinfo from './Components/UserInfo';

function App() {

  return (
    <div className="App">
      <ToastContainer />
      
    <Router>
      <Routes>
        <Route path="/homepage" element={<HomePage /> }/>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/" element={<Login />} />
        <Route path='/addnewstudy' element={<Addnewstudy />} />
        <Route path='/mystudies' element={<Mystudies />} />
        <Route path='/studiesdata/:id' element={<StudiesData />} />
        <Route path='/forgotpassword' element={<ForgotPass />} />
        <Route path='/resetpassword/:id/:usertoken' element={<ResetPass />} />
<Route path='/tabledata' element={<TableData/>}/>
<Route path='/edit/:id' element={<Editstudy/>}></Route>
<Route path='/Editsubjectinfo/:id' element={<Editsubjectinfo/>}></Route>
<Route path='/userinfo' element={<Userinfo/>}></Route>
      </Routes>
    </Router>
    
    </div>
  );
}


export default App;

