// import { Button } from "antd";
// import React, { useEffect, useRef, useState } from "react";
// import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import axios from 'axios';
// import moment from "moment";
// import {useReactToPrint} from 'react-to-print'
// import { toast } from 'react-toastify';
// import Navbar from "../Header/Navbar";
// import {deletestudy, getUsernameByEmail, getstudybyid, getstudydata, study, updatestudybyid} from '../../Utils/Contancts'
// import { SlCalender } from "react-icons/sl";
// import TableData from "../TableData";
// import { FaEdit } from "react-icons/fa";
// import { MdDelete } from "react-icons/md";
                                                
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { format } from 'date-fns';



// function StudiesData() {

//     const navigate = useNavigate()

//     const { id } = useParams();
//     const [data, setData] = useState(null);
//     const [subjectNo, setSubjectNo] = useState("");
//     const [referenceDate, setReferenceDate] = useState("");
//     const [plusnum, setPlusnum] = useState({});
//     const [plusvalue, setPlusvalue] = useState("");
//     const [minusnum, setMinusnum] = useState({});
//     const [minusvalue, setMinusvalue] = useState("");
//     const [plusDates, setPlusDates] = useState({});
//     const [minusDates, setMinusDates] = useState({});
//    const [actualDate,setActualDate] = useState({});

//     const [showTable, setShowTable] = useState(false);


//     const [studydata,setStudydata] = useState("")
   
// const [studyid,setStudyid] = useState("")    
// const [name,setName] = useState("")
// const [studyname,setStudyname] = useState("")
// const [subjectno,setSubjectno]=useState("")
// const [studydetail,setStudydetail] = useState("")

// const [actualdata, setActualdata] = useState("");


// const [username, setUsername] = useState("");   
// const userEmail = localStorage.getItem("email");

// const [subjectnoError, setSubjectnoError] = useState("");
// const [referenceDateErrors, setReferenceDateErrors] = useState("");

// const [selectedDate, setSelectedDate] = useState(null);


// // fetch username by email
// useEffect(() => {
// const fetchUsername = async () => {
//     try {
//         const response = await axios.post(getUsernameByEmail,
//             { userEmail }
//         );
//         setUsername(response.data.fullname); // Set the retrieved username
//     } catch (error) {
//         console.error("Error fetching username:", error);
//     }
// };

// if (userEmail) {
//     fetchUsername();
// }
// }, [userEmail]);

// // study data by id

// useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const result = await axios.get(getstudybyid+id);
//                 setData(result.data);
//                 setStudyid(result.data._id)
//                 setName(result.data.username)
//                 setStudyname(result.data.studyname)
//                 setSubjectno(result.data.subjectNo)
//                 setStudydetail(result.data.details)
//                 if (result.data && result.data.details && result.data.details.length > 0) {
//                     const plusValues = {};
//                     const minusValues = {};
//                     result.data.details.forEach(detail => {
//                         plusValues[detail.visit] = {
//                            day:detail.day,
//                            dayunit:detail.dayunit,
//                             plus: detail.plus,
//                             plusunit: detail.plusunit
//                         };
//                         minusValues[detail.visit] = {
//                             minus: detail.minus,
//                             minusunit: detail.minusunit
//                         };
//                     });
//                     setPlusnum(plusValues);
//                     setMinusnum(minusValues);
//                     console.log("plus number",plusnum);
//                     console.log("MINUS nUMBER", minusnum);

//                     }
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         fetchData();

//         return () => {};
//     }, [id]);

   
//     const printRef = useRef(null)
//     const handlePrint = useReactToPrint({
//       content: () => printRef.current,
//     });

//   const call = () => {
//     navigate('/tabledata')
// }  


// const validateInputs = () => {
//     let isValid = true;
//     if (subjectNo.trim() === "") {
//         setSubjectnoError("Please enter a Subject Number");
//         isValid = false;
//     } else {
//         setSubjectnoError("");
//     }

//     if (referenceDate.trim() === "") {
//         setReferenceDateErrors("Please select a Reference Date");
//         isValid = false;
//     } else {
//         setReferenceDateErrors("");
//     }

//     return isValid;
// };

// // function change the date plus and minus
// const handleUpdate = () => {
//     if (!validateInputs()) {
//         return;
//     }
//     try {
//         setShowTable(true);
//         const newPlusDates = {};
//         const newMinusDates = {};
//         const newActualDate = {};

//         if (plusnum && Object.keys(plusnum).length) {
//             Object.entries(plusnum).forEach(([visit, { day, dayunit }]) => {
//                 const plusNumber = Number(day);
//                 if (plusNumber !== 0) {
//                     let refDate = moment(referenceDate); // Take reference date for each visit
//                     let visitDate;
//                     if (dayunit === "Year") {
//                         visitDate = refDate.add(plusNumber, "years").format("MM-DD-YYYY");
//                     } else if (dayunit === "Month") {
//                         visitDate = refDate.add(plusNumber, "months").format("MM-DD-YYYY");
//                     } else if (dayunit === "Week") {
//                         visitDate = refDate.add(plusNumber * 7, "days").format("MM-DD-YYYY");
//                     } else {
//                         visitDate = refDate.add(plusNumber, "days").format("MM-DD-YYYY");
//                     }
//                    newActualDate[visit] = visitDate;
//                 }
//             });
//         }





//         if (plusnum && Object.keys(plusnum).length) {
//             Object.entries(plusnum).forEach(([visit, { plus, plusunit }]) => {
//                 const plusNumber = Number(plus);
//                 if (plusNumber !== 0) {
//                     let refDate = moment(referenceDate); // Take reference date for each visit
//                     let visitDate;
//                     if (plusunit === "Year") {
//                         visitDate = refDate.add(plusNumber, "years").format("MM-DD-YYYY");
//                     } else if (plusunit === "Month") {
//                         visitDate = refDate.add(plusNumber, "months").format("MM-DD-YYYY");
//                     } else if (plusunit === "Week") {
//                         visitDate = refDate.add(plusNumber * 7, "days").format("MM-DD-YYYY");
//                     } else {
//                         visitDate = refDate.add(plusNumber, "days").format("MM-DD-YYYY");
//                     }
//                     newPlusDates[visit] = visitDate;
//                 }
//             });
//         }
//         if (minusnum && Object.keys(minusnum).length) {
//             Object.entries(minusnum).forEach(([visit, { minus, minusunit }]) => {
//                 const minusNumber = Number(minus);
//                 if (minusNumber !== 0) {
//                     let refDate = moment(referenceDate); // Take reference date for each visit
//                     let visitDate;
//                     if (minusunit === "Year") {
//                         visitDate = refDate.subtract(minusNumber, "years").format("MM-DD-YYYY");
//                     } else if (minusunit === "Month") {
//                         visitDate = refDate.subtract(minusNumber, "months").format("MM-DD-YYYY");
//                     } else if (minusunit === "Week") {
//                         visitDate = refDate.subtract(minusNumber * 7, "days").format("MM-DD-YYYY");
//                     } else {
//                         visitDate = refDate.subtract(minusNumber, "days").format("MM-DD-YYYY");
//                     }
//                     newMinusDates[visit] = visitDate;
//                 }
//             });
//         }

//         setPlusDates(newPlusDates);
//         setMinusDates(newMinusDates);
//         setActualDate(newActualDate)
//         // console.log("Plus Dates:", newPlusDates);
//         // console.log("Minus Dates:", newMinusDates);

//     } catch (error) {
//         console.error("Error occurred:", error);
//     }
// }

// // submit button 
//   const handlesave = async (e) => {
//     e.preventDefault();
//     if (!validateInputs()) {
//         return;
//     }
   
//     const details = [];
  
//     try {
//       const studyResponse = await axios.post(study, {
//         studyid:studyid,
//         username:username,
//         studyname:studyname,
//         subjectNo:subjectNo,
//         details:studydetail,
//         plusDates:plusDates,
//         minusDates:minusDates,
//         actualDate:actualDate
//       });
//       toast.success("Subject No. Added Successfully");
      
//     setStudydata(''); 
//       console.log("Study added successfully:", studyResponse.data);
//     //   window.location.reload();
// navigate('/tabledata')
// // call()
//     } catch (error) {
//       console.error("Error adding study:", error);
//     }
  
  
//   };





// useEffect(() => {
//     const fetchData = async () => {
//         try {
//             const result = await axios.get(getstudydata);
//             let filteredData;
//             if (username === "Admin") {
//                 // If username is "admin", filter data based only on studyid
//                 filteredData = result.data.filter(item => item.studyid === studyid);
//             } else {
//                 // Filter the fetched data based on both studyid and username
//                 filteredData = result.data.filter(item => item.studyid === studyid && item.username === username);
//             }
//             // Set the filtered data in the state
//             setActualdata(filteredData);
//         } catch (error) {
//             console.error('Error fetching data:', error);
//         }
//     };

//     fetchData();

//     return () => { };
// }, [studyid, username]);









// const handledelete = async (id, subject, visit, minus, date, plus) => {
//     try {
//         await axios.delete(`https://www.researchschedule.com/delete/${encodeURIComponent(visit)}/${encodeURIComponent(subject)}`);
//         toast.success("Study deleted successfully");
//         window.location.reload()
//         // Update frontend state or trigger a re-fetch of study data if necessary
//     } catch (error) {
//         if (error.response && error.response.status === 404) {
//             toast.error("Study not found. Double-check subject and visit.");
//         } else {
//             console.error("Error deleting study:", error);
//             toast.error("Failed to delete study");
//         }
//     }
// };


// const handleDelete = (id)=>{
//     axios.delete(deletestudy+id)
// .then(res => {console.log(res)
//     toast.success("Deleted Successfully")
//     window.location.reload()
// window.location.reload()
// })
// .catch(err => console.log(err))
//   }
  






// const handleDateChange = (date) => {
//     setSelectedDate(date);
//     setReferenceDate(format(date, 'MM-dd-yyyy'));
//   };


// //   console.log("reference date ",referenceDate);

//     return (
//         <>

// <Navbar/>

//             <div className="d-flex justify-content-center">
//                 <div className="col-md-9 bg-light  p-3">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <form>
//                                 <div className="col ">
//                                     <div className="col-md-6">
//                                         <span className="fs-5 text-dark d-table" >{data && data.studyname}</span>
//                                     </div>
//                                     <div className="col-md-6">
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             placeholder="subject No"
//                                             value={subjectNo}
//                                             onChange={(e) => setSubjectNo(e.target.value)}
//                                         />
//                            {subjectnoError && <p className="text-danger d-table">{subjectnoError}</p>}
                           
//                                     </div>
//                                 </div>
//                                 <br />
//                                 <div className="row ">
//                                     <div className="col-md-3 ">
//                                         <span className="fs-5 text-dark d-table">Reference Date</span>
//                                         {/* <input
//                                             type="date"
//                                             placeholder="Please Select Reference Date"
//                                             className="form-control"
                                          
//                                             value={referenceDate}
//                                             onChange={(e) => setReferenceDate(e.target.value)}
//                                         /> */}
                                        

//                                         <DatePicker
//                      placeholder="Please Select Reference Date"
//                      className="form-control"
                                                                             
//       selected={selectedDate}
//       onChange={(e)=> handleDateChange(e)}
//       dateFormat="MM-dd-yyyy" 
//     />



                                                     
//                                           {referenceDateErrors && <p className="text-danger d-table">{referenceDateErrors}</p>}
//                                     </div>

                                
                                 
//                                     <div className="col-md-3 mt-2">
//                                         <Button
//                                             className="add-btn" onClick={() => handleUpdate()}>
//                                             Save
//                                         </Button>
//                                     </div>
//                                 </div>
//                                 <br />
//                                 {showTable && (   
//                                 <table  className="table">
//                                     <thead>
//                                         <tr>
//                                             <th></th>
//                                             <th>Minus</th>
//                                             <th>Actual Date</th>
//                                             <th>Plus</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {data && data.details && data.details.map((detail, index) => (
//                                             <tr key={index} className="">
//                                                 <td>
//                                                     <div className="col">
//                                                         <span className="h5 text-dark">{detail.visit}</span>
//                                                     </div>
//                                                 </td>
//                                                 <td>
//                                                     <div className="">
//                                                         <input placeholder="" type="" value={minusDates[detail.visit] || ""} className="form-control"></input>
//                                                     </div>
//                                                 </td>
//                                                 <td>
//                                                     <div className="">
                                                        
//                                                         <input placeholder="" type="" value={actualDate[detail.visit] || ""} className="form-control"></input>
//                                                     </div>
//                                                 </td>
//                                                 <td>
//                                                     <div className="">
                                                        
//                                                         <input placeholder="" type="" value={plusDates[detail.visit] || ""} className="form-control"></input>
//                                                     </div>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                               )}

//                                 <br />
//                                 <div className="row">
//                                     <div className="col">
//                                         <Button
//                                             className="add-btn"
//                                        onClick={handlesave}
//                                        >
//                                             Save
//                                         </Button>
//                                         <Button className="add-btn" onClick={handlePrint}>
//                                             Print
//                                         </Button>
//                                     </div>
//                                 </div>
                 


//                                <table className="table">
//                 <thead>

//                 </thead>
//                 <tbody>
//                     {actualdata && actualdata.map((item, index) => (
//                         <div key={index}>
//                             <table className="table" key={index}>
//                                 <thead>
//                                     <tr>
//                                         <th>{item.subjectNo}</th>
//                                         <th>Minus</th>
//                                         <th>Actual Date</th>
//                                         <th>Plus</th>
//                                         <th>
//                                         <Link to={`/edit/${item._id}`} className="h5" ><FaEdit /></Link>
                                       
//                                         <MdDelete onClick={(e)=> handleDelete(item._id)} className="text-primary h5" /> 
                                       
//                                         </th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {Object.entries(item.details).map((detail, index) => (
//                                         <tr key={index} className="">
//                                             <td>
//                                                 <div className="col">
//                                                     <span className="h5 text-dark">{detail[1].visit}</span>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="">
//                                                     <input placeholder="" type="" value={item.minusDates[detail[1].visit] || ""} className="form-control"></input>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="">
//                                                     <input placeholder="" type="" value={item.actualDate[detail[1].visit] || ""} className="form-control"></input>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div className="">
//                                                     <input placeholder="" type="" value={item.plusDates[detail[1].visit] || ""} className="form-control"></input>
//                                                 </div>
//                                             </td>
//                                             <td>
//                                             <MdDelete onClick={(e) => { e.preventDefault(); handledelete(item._id,item.subjectNo,detail[1].visit,item.minusDates[detail[1].visit], item.actualDate[detail[1].visit], item.plusDates[detail[1].visit] ); }} className="text-primary h5" /> 
                                       
//                                                 </td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     )).reverse()}

//                 </tbody>
//             </table>
                               
                               
   
//                                {/* <table className="table" ref={printRef}>
//     <thead>
//         <tr>
//             <th>Subject No</th>
//             <th>Minus</th>
//             <th>Actual Date</th>
//             <th>Plus</th>
//             <th>Actions</th>
//         </tr>
//     </thead>
//     <tbody>
//         {actualdata && actualdata.map((item, index) => (
//             <React.Fragment key={index}>
//                 <tr>
//                     <td colSpan="5" className="text-center">Subject: {item.subjectNo}</td>
//                 </tr>
//                 {Object.entries(item.details).map((detail, idx) => (
//                     <tr key={idx}>
//                         <td>{detail[1].visit}</td>
//                         <td>
//                             <input placeholder="" type="" value={item.minusDates[detail[1].visit] || ""} className="form-control" />
//                         </td>
//                         <td>
//                             <input placeholder="" type="" value={item.actualDate[detail[1].visit] || ""} className="form-control" />
//                         </td>
//                         <td>
//                             <input placeholder="" type="" value={item.plusDates[detail[1].visit] || ""} className="form-control" />
//                         </td>
//                         <td>
//                             <Link to={`/edit/${item._id}`} className="h5"><FaEdit /></Link>
//                             <MdDelete onClick={(e) => { e.preventDefault(); handleDelete(item._id) }} className="text-danger h5" />
//                         </td>
//                     </tr>
//                 ))}
//             </React.Fragment>
//         ))}
//     </tbody>
// </table> */}




                               
//                                 {/* <TableData/> */}
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default StudiesData;
























import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import moment from "moment";
import {useReactToPrint} from 'react-to-print'
import { toast } from 'react-toastify';
import Navbar from "../Header/Navbar";
import {deletestudy, getUsernameByEmail, getstudybyid, getstudydata, study, updatestudybyid} from '../../Utils/Contancts'
import { SlCalender } from "react-icons/sl";
import TableData from "../TableData";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
                                                
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';



function StudiesData() {

    const navigate = useNavigate()

    const { id } = useParams();
    const [data, setData] = useState(null);
    const [subjectNo, setSubjectNo] = useState("");
    const [referenceDate, setReferenceDate] = useState("");
    const [plusnum, setPlusnum] = useState({});
    const [plusvalue, setPlusvalue] = useState("");
    const [minusnum, setMinusnum] = useState({});
    const [minusvalue, setMinusvalue] = useState("");
    const [plusDates, setPlusDates] = useState({});
    const [minusDates, setMinusDates] = useState({});
   const [actualDate,setActualDate] = useState({});

    const [showTable, setShowTable] = useState(false);


    const [studydata,setStudydata] = useState("")
   
const [studyid,setStudyid] = useState("")    
const [name,setName] = useState("")
const [studyname,setStudyname] = useState("")
const [subjectno,setSubjectno]=useState("")
const [studydetail,setStudydetail] = useState("")

const [actualdata, setActualdata] = useState("");


const userEmail = localStorage.getItem("email");

const [subjectnoError, setSubjectnoError] = useState("");
const [referenceDateErrors, setReferenceDateErrors] = useState("");

const [selectedDate, setSelectedDate] = useState(null);



// study data by id

useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios.get(getstudybyid+id);
                setData(result.data);
                setStudyid(result.data._id)
                setName(result.data.email)
                setStudyname(result.data.studyname)
                setSubjectno(result.data.subjectNo)
                setStudydetail(result.data.details)
                if (result.data && result.data.details && result.data.details.length > 0) {
                    const plusValues = {};
                    const minusValues = {};
                    result.data.details.forEach(detail => {
                        plusValues[detail.visit] = {
                           day:detail.day,
                           dayunit:detail.dayunit,
                            plus: detail.plus,
                            plusunit: detail.plusunit
                        };
                        minusValues[detail.visit] = {
                            minus: detail.minus,
                            minusunit: detail.minusunit
                        };
                    });
                    setPlusnum(plusValues);
                    setMinusnum(minusValues);
                    console.log("plus number",plusnum);
                    console.log("MINUS nUMBER", minusnum);

                    }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        return () => {};
    }, [id]);

   
    // const printRef = useRef(null)
//     const handlePrint = useReactToPrint({
//       content: () => printRef.current,
//     });

const tableRef = useRef(); // Use ref to get the table


const handlePrint = () => {
    const printContents = tableRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    
    // Set the body to only display the table content
    document.body.innerHTML = printContents;
    window.print();  // Trigger the print dialog
    document.body.innerHTML = originalContents; // Restore the original content
  };


  const call = () => {
    navigate('/tabledata')
}  


const validateInputs = () => {
    let isValid = true;
    if (subjectNo.trim() === "") {
        setSubjectnoError("Please enter a Subject Number");
        isValid = false;
    } else {
        setSubjectnoError("");
    }

    if (referenceDate.trim() === "") {
        setReferenceDateErrors("Please select a Reference Date");
        isValid = false;
    } else {
        setReferenceDateErrors("");
    }

    return isValid;
};

// function change the date plus and minus
const handleUpdate = () => {
    if (!validateInputs()) {
        return;
    }
    try {
        setShowTable(true);
        const newPlusDates = {};
        const newMinusDates = {};
        const newActualDate = {};

        if (plusnum && Object.keys(plusnum).length) {
            Object.entries(plusnum).forEach(([visit, { day, dayunit }]) => {
                const plusNumber = Number(day);
                if (plusNumber !== 0) {
                    let refDate = moment(referenceDate); // Take reference date for each visit
                    let visitDate;
                    if (dayunit === "Year") {
                        visitDate = refDate.add(plusNumber, "years").format("MM-DD-YYYY");
                    } else if (dayunit === "Month") {
                        visitDate = refDate.add(plusNumber, "months").format("MM-DD-YYYY");
                    } else if (dayunit === "Week") {
                        visitDate = refDate.add(plusNumber * 7, "days").format("MM-DD-YYYY");
                    } else {
                        visitDate = refDate.add(plusNumber, "days").format("MM-DD-YYYY");
                    }
                   newActualDate[visit] = visitDate;
                }
            });
        }





        if (plusnum && Object.keys(plusnum).length) {
            Object.entries(plusnum).forEach(([visit, { plus, plusunit }]) => {
                const plusNumber = Number(plus);
                if (plusNumber !== 0) {
                    let refDate = moment(referenceDate); // Take reference date for each visit
                    let visitDate;
                    if (plusunit === "Year") {
                        visitDate = refDate.add(plusNumber, "years").format("MM-DD-YYYY");
                    } else if (plusunit === "Month") {
                        visitDate = refDate.add(plusNumber, "months").format("MM-DD-YYYY");
                    } else if (plusunit === "Week") {
                        visitDate = refDate.add(plusNumber * 7, "days").format("MM-DD-YYYY");
                    } else {
                        visitDate = refDate.add(plusNumber, "days").format("MM-DD-YYYY");
                    }
                    newPlusDates[visit] = visitDate;
                }
            });
        }
        if (minusnum && Object.keys(minusnum).length) {
            Object.entries(minusnum).forEach(([visit, { minus, minusunit }]) => {
                const minusNumber = Number(minus);
                if (minusNumber !== 0) {
                    let refDate = moment(referenceDate); // Take reference date for each visit
                    let visitDate;
                    if (minusunit === "Year") {
                        visitDate = refDate.subtract(minusNumber, "years").format("MM-DD-YYYY");
                    } else if (minusunit === "Month") {
                        visitDate = refDate.subtract(minusNumber, "months").format("MM-DD-YYYY");
                    } else if (minusunit === "Week") {
                        visitDate = refDate.subtract(minusNumber * 7, "days").format("MM-DD-YYYY");
                    } else {
                        visitDate = refDate.subtract(minusNumber, "days").format("MM-DD-YYYY");
                    }
                    newMinusDates[visit] = visitDate;
                }
            });
        }

        setPlusDates(newPlusDates);
        setMinusDates(newMinusDates);
        setActualDate(newActualDate)
      

    } catch (error) {
        console.error("Error occurred:", error);
    }
}

// submit button 
  const handlesave = async (e) => {
    e.preventDefault();
    if (!validateInputs()) {
        return;
    }
   
    const details = [];
  
    try {
      const studyResponse = await axios.post(study, {
        studyid:studyid,
        email:userEmail,
        studyname:studyname,
        subjectNo:subjectNo,
        details:studydetail,
        plusDates:plusDates,
        minusDates:minusDates,
        actualDate:actualDate
      });
      toast.success("Subject No. Added Successfully");
      
    setStudydata(''); 
      console.log("Study added successfully:", studyResponse.data);
    //   window.location.reload();
navigate('/tabledata')
// call()
    } catch (error) {
      console.error("Error adding study:", error);
    }
  
  
  };





useEffect(() => {
    const fetchData = async () => {
        try {
            const result = await axios.get(getstudydata);
            let filteredData;
            if (userEmail === "admin@mdfirstresearch.com") {
                // If username is "admin", filter data based only on studyid
                filteredData = result.data.filter(item => item.studyid === studyid);
            } else {
                // Filter the fetched data based on both studyid and username
                filteredData = result.data.filter(item => item.studyid === studyid && item.email === userEmail);
            }
            // Set the filtered data in the state
            setActualdata(filteredData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();

    return () => { };
}, [studyid, userEmail]);









const handledelete = async (id, subject, visit, minus, date, plus) => {
    try {
        await axios.delete(`https://www.researchschedule.com/delete/${encodeURIComponent(visit)}/${encodeURIComponent(subject)}`);
        toast.success("Study deleted successfully");
        window.location.reload()
        // Update frontend state or trigger a re-fetch of study data if necessary
    } catch (error) {
        if (error.response && error.response.status === 404) {
            toast.error("Study not found. Double-check subject and visit.");
        } else {
            console.error("Error deleting study:", error);
            toast.error("Failed to delete study");
        }
    }
};


const handleDelete = (id)=>{
    axios.delete(deletestudy+id)
.then(res => {console.log(res)
    toast.success("Deleted Successfully")
    window.location.reload()
window.location.reload()
})
.catch(err => console.log(err))
  }
  






const handleDateChange = (date) => {
    setSelectedDate(date);
    setReferenceDate(format(date, 'MM-dd-yyyy'));
  };


    return (
        <>

<Navbar/>

            <div className="d-flex justify-content-center">
                <div className="col-md-9 bg-light  p-3">
                    <div className="row">
                        <div className="col-md-12">
                            <form>
                                <div className="col ">
                                    <div className="col-md-6">
                                        <span className="fs-5 text-dark d-table" >{data && data.studyname}</span>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="subject No"
                                            value={subjectNo}
                                            onChange={(e) => setSubjectNo(e.target.value)}
                                        />
                           {subjectnoError && <p className="text-danger d-table">{subjectnoError}</p>}
                           
                                    </div>
                                </div>
                                <br />
                                <div className="row ">
                                    <div className="col-md-3 ">
                                        <span className="fs-5 text-dark d-table">Reference Date</span>
                                     
                                        

                                        <DatePicker
                     placeholder="Please Select Reference Date"
                     className="form-control"
                                                                             
      selected={selectedDate}
      onChange={(e)=> handleDateChange(e)}
      dateFormat="MM-dd-yyyy" 
    />



                                                     
                                          {referenceDateErrors && <p className="text-danger d-table">{referenceDateErrors}</p>}
                                    </div>

                                
                                 
                                    <div className="col-md-3 mt-2">
                                        <Button
                                            className="add-btn" onClick={() => handleUpdate()}>
                                            Save
                                        </Button>
                                    </div>
                                </div>
                                <br />
                                {showTable && (   
                                <table  className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Minus</th>
                                            <th>Actual Date</th>
                                            <th>Plus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data && data.details && data.details.map((detail, index) => (
                                            <tr key={index} className="">
                                                <td>
                                                    <div className="col">
                                                        <span className="h5 text-dark">{detail.visit}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <input placeholder="" type="" value={minusDates[detail.visit] || ""} className="form-control"></input>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        
                                                        <input placeholder="" type="" value={actualDate[detail.visit] || ""} className="form-control"></input>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        
                                                        <input placeholder="" type="" value={plusDates[detail.visit] || ""} className="form-control"></input>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                              )}

                                <br />
                                <div className="row">
                                    <div className="col">
                                        <Button
                                            className="add-btn"
                                       onClick={handlesave}
                                       >
                                            Save
                                        </Button>
                                        <Button className="add-btn" onClick={handlePrint}>
                                            Print
                                        </Button>
                                    </div>
                                </div>
                 


                               <table ref={tableRef} className="table">
                <thead>

                </thead>
                <tbody>
                    {actualdata && actualdata.map((item, index) => (
                        <div key={index}>
                            <table className="table" key={index}>
                                <thead>
                                    <tr>
                                        <th>{item.subjectNo}</th>
                                        <th>Minus</th>
                                        <th>Actual Date</th>
                                        <th>Plus</th>
                                        <th>
                                        <Link to={`/edit/${item._id}`} className="h5" ><FaEdit /></Link>
                                       
                                        <MdDelete onClick={(e)=> handleDelete(item._id)} className="text-primary h5" /> 
                                       
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(item.details).map((detail, index) => (
                                        <tr key={index} className="">
                                            <td>
                                                <div className="col">
                                                    <span className="h5 text-dark">{detail[1].visit}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                    <input placeholder="" type="" value={item.minusDates[detail[1].visit] || ""} className="form-control"></input>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                    <input placeholder="" type="" value={item.actualDate[detail[1].visit] || ""} className="form-control"></input>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="">
                                                    <input placeholder="" type="" value={item.plusDates[detail[1].visit] || ""} className="form-control"></input>
                                                </div>
                                            </td>
                                            <td>
                                            <MdDelete onClick={(e) => { e.preventDefault(); handledelete(item._id,item.subjectNo,detail[1].visit,item.minusDates[detail[1].visit], item.actualDate[detail[1].visit], item.plusDates[detail[1].visit] ); }} className="text-primary h5" /> 
                                       
                                                </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )).reverse()}

                </tbody>
            </table>
                               
                               
   
 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudiesData;
