import React, { useState } from "react";
import InputComponents from "../Input";
import Button from "../Button";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { createlogin, forgotpass } from '../../Utils/Contancts'
import { toast } from "react-toastify";

function ResetPass() {
    const [password, setPassword] = useState("");
    // const [confirmPass, setConfirmPass] = useState("");
    const navigate = useNavigate();

    const { id, usertoken } = useParams()

    const handlereset = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://www.researchschedule.com/resetpassword/${id}/${usertoken}`, {
                password,
            });
            console.log("Signup response..", response);
            toast.success("Password reset successfully!");
            navigate("/");

        }
        catch (error) {
            console.log("error...", error);
            toast.error("Failed to reset password. Please try again.");
        }
    }

    return (
        <div>
            <div className="d-flex flex-column justify-content-center mt-5 ">
                <div className="d-flex  justify-content-center">
                    <form className="d-flex flex-column  bg-light p-2 rounded" style={{ justifyContent: "center", width: "450px" }}>
                        <h3>Reset Password</h3>

                        <InputComponents
                            state={password}
                            setState={setPassword}
                            placeholder="New Password"
                            type="password"
                        />

                        <Button text={"Update"} onClick={handlereset} />

                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPass