import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { alluser } from '../../Utils/Contancts';
import { Button } from 'antd';
import { useReactToPrint } from 'react-to-print'
import { IoMdMailUnread } from "react-icons/io";

import './Userinfo.css'
function Usertable() {
    const [userdata, setUserdata] = useState([]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(alluser);
                setUserdata(response.data);
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, []);

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    const exportToCSV = () => {
        const tableRef = printRef.current;
        const rows = tableRef.querySelectorAll("tbody tr");
        const headers = tableRef.querySelectorAll("thead th");

        const columnNames = Array.from(headers).map(header => header.textContent);

        const csvRows = [];
        csvRows.push(columnNames.join(","));

        rows.forEach((row) => {
            const csvRow = [];
            row.querySelectorAll("td").forEach((cell) => {
                csvRow.push(cell.textContent);
            });
            csvRows.push(csvRow.join(","));
        });

        const csvContent = csvRows.join("\n");
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "study_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const redirectToEmail = (email) => {
        window.location.href = `mailto:${email}`;
    };

    return (
        <>
            <div className='row bg-light mx-auto p-2'>
                <div className='col-md-12 '>
                    <Button className='download' onClick={exportToCSV}>Download</Button>
                    <Button className='download' onClick={handlePrint}>Print</Button>

                    <table className="table" ref={printRef}>
                        <thead>
                            <tr>
                                <th scope="col">User Name</th>
                                <th scope="col">Contact</th>
                                <th scope="col">Email</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {userdata.map((user, index) => (
                                <tr key={index}>
                                    <td>{user.fullName}</td>
                                    <td>{user.contact}</td>
                                   
                                    <td className=''>{user.email}</td>
                                    <td className='email-icon'><a href={`mailto:${user.email}`} onClick={(e) => { e.preventDefault(); redirectToEmail(user.email); }}><IoMdMailUnread /></a></td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Usertable;